<div *ngIf="(themeOption$ | async)?.theme as theme">
  <div
    [ngStyle]="{
      background: theme.myTheme?.headerBgColor
        ? 'var(--theme-header-bg)'
        : ''
    }"
    class="banner d-flex flex-column align-items-center py-4"
  >
    <h1 class="title text-center">
      {{
        theme.myTheme?.headerName ||
          "Welcome " + authService.getUsername() + "!"
      }}
    </h1>
  </div>

  <div class="container">
    <div class="content-container">
      <div class="row justify-content-between align-items-center mb-3">
        <h4 class="sub-title col-6 col-lg-3 m-0">
          {{
            theme.myTheme?.yourFavouriteApp || "Your favourite tools"
          }}
        </h4>
        <div [ngSwitch]="theme.myTheme?.usLocale" class="col-6 col-lg-3 d-flex justify-content-end">
          <a *ngSwitchCase="true" class="text-uppercase see-all-link"[routerLink]="['/favourites']" tabindex="0">
          view all favorite tools
        </a>
        <a *ngSwitchDefault class="text-uppercase see-all-link"[routerLink]="['/favourites']" tabindex="0">
          view all favourite tools
        </a>
        </div>

      <div class="container p-0 mb-6">
        <app-drag-and-drop-tools
          *ngIf="favourites.length > 0"
          (removeItemEmitter)="removeFavourite($event)"
          [bookmarks]="limitedFavourites"
          [rowCount]="getRowCount(favourites.length)"
        ></app-drag-and-drop-tools>
        <div *ngIf="loadingTools">
          <div class="d-flex justify-content-center">
            <div
              class="spinner-border"
              style="width: 3rem; height: 3rem"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="col">
          <h1 *ngIf="!loadingTools && !(favourites.length > 0)" [ngSwitch]="theme?.myTheme?.usLocale">
            <em class="text-muted pt-3" *ngSwitchCase="true">
              No favorite tools added yet, click on
              <a class="text-muted" [routerLink]="['/favourites']" tabindex="0"
                ><u>here</u></a
              >
              to add some!
            </em>
            <em class="text-muted pt-3" *ngSwitchDefault>
              No favourite tools added yet, click on
              <a class="text-muted" [routerLink]="['/favourites']" tabindex="0"
                ><u>here</u></a
              >
              to add some!
            </em>
          </h1>
        </div>
      </div>
    </div>

    <div *ngIf="!theme.myTheme?.hideStories" class="content-container m-0 pb-6">
      <div class="row justify-content-between align-items-center mb-3">
        <h2 class="sub-title col-6 col-lg-3 m-0">
          {{
             theme.myTheme?.storiesTitle || "Your favourite stories"
          }}
        </h2>
        <div class="col-6 col-lg-3 d-flex justify-content-end">
          <a
            class="text-uppercase see-all-link"
            [routerLink]="['/stories']"
            tabindex="0"
          >
            read all stories
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="container p-0">
            <div *ngIf="loadingStories">
              <div class="d-flex justify-content-center">
                <div
                  class="spinner-border"
                  style="width: 3rem; height: 3rem"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <app-drag-and-drop-stories
              *ngIf="favouriteStories.length > 0"
              (removeItemEmitter)="removeFavouriteStory($event)"
              [blogs]="limitedFavouriteStories"
              [rowCount]="getRowCount(favouriteStories.length)"
              [isFavourite]="true"
            ></app-drag-and-drop-stories>
            <h1
              class="col-12 p-0"
              *ngIf="!loadingStories && !(favouriteStories.length > 0)"
              [ngSwitch]="theme?.myTheme?.usLocale"
            >
              <em class="text-muted pt-3" *ngSwitchCase="true">
                No favorite stories added yet, click on
                <a
                  tabindex="0"
                  [routerLink]="['/stories']"
                  class="text-muted pt-3"
                  ><u>here</u></a
                >
                to add some!
              </em>

              <em class="text-muted pt-3" *ngSwitchDefault>
                No favourite stories added yet, click on
                <a
                  tabindex="0"
                  [routerLink]="['/stories']"
                  class="text-muted pt-3"
                  ><u>here</u></a
                >
                to add some!
              </em>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
