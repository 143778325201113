import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
  private boardToken = '68b97477-7b49-037e-5a88-97941d1b74b9';

  constructor(private http: HttpClient, private authService: AuthService) { }

  ngOnInit(): void {
    this.fetchSsoToken();
  }

  fetchSsoToken(): void{
    this.authService.checkIfLoggedIn()
      .then(() => {
      this.http.post<any>(environment.cannyTokenEndpoint, {}, {withCredentials: true})
        .subscribe(response2 => {
        this.renderCannyFeedbackPage(response2.ssoToken);
      });
    })
      .catch(() => {
        this.authService.redirectToLoginPage();
      });
  }

  renderCannyFeedbackPage(ssoToken: string): void{
    // @ts-ignore
    Canny('render', {
      boardToken: this.boardToken,
      basePath: '/feedback', // See step 2
      ssoToken, // See step 3
    });
  }

}
