<div>
  <div *ngIf="showShortMarkdown; else fullDescription">
    <div [innerHTML]="shortMarkDown"></div>
    <div class="d-flex">
      <span class="expand-collapse d-flex align-items-center" (click)="showShortMarkdown = false">
        READ MORE <em class="material-icons-outlined" style="color: inherit!important;">expand_more</em>
      </span>
    </div>
  </div>

  <ng-template #fullDescription>
    <div [innerHTML]="markedDescription"></div>
    <div *ngIf="shortMarkDown" class="d-flex">
      <span class="expand-collapse d-flex align-items-center" (click)="showShortMarkdown = true">
        READ LESS <em class="material-icons-outlined" style="color: inherit!important;">expand_less</em>
      </span>
    </div>
  </ng-template>
</div>
