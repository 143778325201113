import { FavouritesModule } from './favourites/favourites.module';
import { ToolsModule } from './tools/tools.module';
import { StoriesModule } from './stories/stories.module';
import { CoreModule } from './core/core.module';
import { HomeModule } from './home/home.module';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {NgbToastModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SentryErrorHandlerService} from './core/services/sentry-error-handler/sentry-error-handler.service';
import {environment} from '../environments/environment';
import { FooterService } from './core/services/footers/footers.service';
import {ApolloModule} from 'apollo-angular';

const usesSentryErrorHandlingService = environment.production && environment.sentryDSN;
const sentryErrorHandlingProviders = [
  {provide: ErrorHandler, useClass: SentryErrorHandlerService}
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    SharedModule,
    HomeModule,
    CoreModule,
    StoriesModule,
    ToolsModule,
    FavouritesModule,
    HttpClientModule,
    FormsModule,
    DragDropModule,
    NgbToastModule,
    NgbModule,
    GraphQLModule,
    ApolloModule,
  ],
  providers: [
    [FooterService, ...(usesSentryErrorHandlingService ? sentryErrorHandlingProviders : [])],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
