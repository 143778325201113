import { Component, OnInit } from '@angular/core';
import { BlogsService } from '../../../shared/services/blogs/blogs.service';
import LABELS from 'src/app/shared/data/ui-labels.json';
import { Breadcrumbs } from 'src/app/shared/interfaces/breadcrumbs';
import { Blog } from 'src/app/shared/interfaces/blog';
@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  breadcrumbs: Breadcrumbs[] = [
    {
      label: 'Home',
      slug: '/home',
    },
    {
      label: 'Stories',
      slug: '/stories',
    },
  ];
  news = LABELS.UI.NEWS;
  newsTitle = LABELS.UI.NEWS_TITLE;
  filteredPromotedBlogs: Blog[] = [];
  filteredBlogs: Blog[] = [];
  searchInput = '';
  promotedBlogs: Blog[] = [];
  blogs: Blog[] = [];
  loading = true;

  constructor(public blogService: BlogsService) {}

  ngOnInit(): void {
    this.fetchAllBlogs();
    this.filterBlogs();
  }

  fetchAllBlogs(): void {
    this.blogService
      .getAllBlogs()
      .then((result: any) => {
        this.promotedBlogs = result.data.fetchAllPromotedBlogsByRole;
        this.blogs = result.data.fetchAllBlogsByRole;
        this.loading = result.loading;
        this.filterBlogs();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getBlogsFromThemeOptions(data: Blog[], limit: number): Blog[] {
    return data.slice(0, limit);
  }

  filterBlogs(): void {
    this.filteredPromotedBlogs = this.blogService.filterPromotedBlogsByTitle(
      this.promotedBlogs,
      this.searchInput
    );
    this.filteredBlogs = this.blogService.filterBlogsByTitle(
      this.blogs,
      this.searchInput
    );
  }
}
