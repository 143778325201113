import {Injectable} from '@angular/core';
import {SideBarItem} from '../../interfaces/side-bar-item';
import {of, Observable, BehaviorSubject} from 'rxjs';
import {LocalStorageService} from '../../../shared/services/local-storage/local-storage.service';
import LABELS from 'src/app/shared/data/ui-labels.json';
import { ThemeOption, ThemeOptionsService } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  boundThemeOption?: ThemeOption;
  pagesToRender: SideBarItem[] = [
    {
      name: 'Home',
      path: '/home',
      iconTitle: 'home',
    },
    {
      name: 'My favourites',
      path: '/favourites',
      iconTitle: 'favorite',
    },
    {
      name: 'All tools',
      path: '/tools',
      iconTitle: 'handyman',
    },
    {
      name: this.capitalizeFirstLetter(LABELS.UI.NEWS),
      path: '/stories',
      iconTitle: 'library_books',
    },
  ];
  private collapsed = new BehaviorSubject<boolean>(
    this.getStoredCollapsedState()
  );

  constructor(private localStorageService: LocalStorageService,
    private themeOptionsService: ThemeOptionsService) {
  }

  capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  toggleSidebar(): void {
    this.collapsed.next(!this.collapsed.getValue());
    this.localStorageService.updateLocalStorage(
      'collapsed',
      this.collapsed.getValue()
    );
  }

  getPagesToRender(): Observable<SideBarItem[]> {
      this.themeOptionsService.getThemeOption().then((theme) => {
        const boundThemeOption = theme?.theme?.myTheme;
        if(!boundThemeOption){
          return;
        }
        const favouritesPage = this.pagesToRender.findIndex(page => page.path === '/favourites');
        const storiesPage = this.pagesToRender.findIndex(page => page.path === '/stories');

        if(boundThemeOption.usLocale) {
          this.pagesToRender[favouritesPage].name = 'My favorites'
        }
        if(boundThemeOption.hideStories) {
          this.pagesToRender.splice(storiesPage, 1);
        }
      });
      
    return of(this.pagesToRender);
  }

  getCollapsedState(): Observable<boolean> {
    return this.collapsed.asObservable();
  }

  setCollapsedState(newValue: boolean): void {
    this.collapsed.next(newValue);
    this.localStorageService.updateLocalStorage(
      'collapsed',
      this.collapsed.getValue()
    );
  }

  getStoredCollapsedState(): boolean {
    let collapsedState =
      this.localStorageService.getLocalDataByKey('collapsed');

    if (!collapsedState) {
      this.localStorageService.updateLocalStorage('collapsed', false);
      collapsedState = false;
    }

    return collapsedState;
  }
}
