import {Component, OnInit} from '@angular/core';
import {SideBarService} from '../../services/side-bar/side-bar.service';
import {Router, UrlTree} from '@angular/router';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {Observable} from 'rxjs';
import {HideMobileMenuService} from '../../services/hide-mobile-menu/hide-mobile-menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  collapsed$ = this.sideBarService.getCollapsedState();
  sideBarItems$ = this.sideBarService.getPagesToRender();
  hideStatus$ = this.hideMobileMenuService.getHideStatus();
  useFeedback$ = Boolean(environment.cannyTokenEndpoint);

  constructor(
    private sideBarService: SideBarService,
    private authService: AuthService,
    private hideMobileMenuService: HideMobileMenuService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  toggleCollapse(): void {
    this.sideBarService.toggleSidebar();
  }

  logout(): Observable<boolean | void | UrlTree> | Promise<boolean | void | UrlTree> {
    return this.authService.logout();
  }
}
