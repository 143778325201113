import gql from 'graphql-tag';

export const FOOTERS = gql`
  query getFooters($key: String!) {
    footers(where: {key: $key}) {
      key
      columns {
        title
        content
      }
    }
  }
`;
