import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';

import * as pipe from './pipes';
import * as component from './components';

@NgModule({
  declarations: [
    component.NotFoundComponent,
    component.ApplicationCardComponent,
    component.AvatarComponent,
    component.BlogComponent,
    component.BreadcrumbComponent,
    component.DragAndDropComponent,
    component.DragAndDropStoriesComponent,
    component.SsoHandlerComponent,
    component.SearchInputComponent,
    pipe.RangePipe,
    pipe.TruncatePipe,
    pipe.Hex2rgbPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    DragDropModule
  ],
  exports: [
    component.NotFoundComponent,
    component.ApplicationCardComponent,
    component.AvatarComponent,
    component.BlogComponent,
    component.BreadcrumbComponent,
    component.DragAndDropComponent,
    component.DragAndDropStoriesComponent,
    component.SsoHandlerComponent,
    component.SearchInputComponent,
    pipe.RangePipe,
    pipe.TruncatePipe,
    pipe.Hex2rgbPipe
  ],
  providers: [
    pipe.RangePipe,
    pipe.TruncatePipe,
    pipe.Hex2rgbPipe
  ],
})
export class SharedModule { }
