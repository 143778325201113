import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import * as component from './components';

@NgModule({
  declarations: [
    component.MobileMenuComponent,
    component.SideBarComponent,
    component.ToastsComponent,
    component.FooterComponent,
    component.FeedbackComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    component.MobileMenuComponent,
    component.SideBarComponent,
    component.ToastsComponent,
    component.FooterComponent,
    component.FeedbackComponent
  ]
})
export class CoreModule { }
