import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Bookmark} from '../../interfaces/bookmark';
import {FavouritesService} from '../../services/favourites/favourites.service';
import {Router} from '@angular/router';
import LABELS from '../../data/ui-labels.json';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {
  @Input() bookmark: Bookmark;
  @Output() removeFavouriteEmitter = new EventEmitter<number>();
  @Input() showDragIcon = false;
  @Input() useFavouritingHeart: boolean;
  bookmarkInfo = LABELS.UI.BOOKMARK_INFO;
  isFavourite: boolean;

  constructor(private favouritesService: FavouritesService, private router: Router) { }

  ngOnInit(): void {
    this.checkIfFavourite();
  }

  redirect(url: string): void {
    window.open(url);
  }

  favouriteClicked(event: Event): void{
    event.stopPropagation();
    if (this.isFavourite){
      this.isFavourite = false;
      this.favouritesService.removeFavourite(this.bookmark.id);
      this.removeFavouriteEmitter.emit(this.bookmark.id);
    } else {
      this.isFavourite = true;
      this.favouritesService.addFavourite(this.bookmark.id);
    }
  }

  checkIfFavourite(): void{
    this.favouritesService.checkIfFavourite(this.bookmark).then(result => {
      this.isFavourite = result;
    });
  }

  navigateToDetailsPage(bookmark: Bookmark): void{
    const bookmarkSlug = bookmark.title.toLowerCase().replace(/ /g, '-') + `-${bookmark.id}`;
    this.router.navigate(['/tools', bookmarkSlug]);
  }
}
