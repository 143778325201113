import { Component, Input, OnInit } from '@angular/core';
import { Bookmark } from 'src/app/shared/interfaces/bookmark';
import marked from 'marked';

@Component({
  selector: 'app-about-the-app',
  templateUrl: './about-the-app.component.html',
  styleUrls: ['./about-the-app.component.scss']
})
export class AboutTheAppComponent implements OnInit {
  @Input() bookmark: Bookmark;
  markedDescription: string;
  shortMarkDown: string;
  showShortMarkdown = false;
  constructor() { }

  ngOnInit(): void {
    this.markedDescription = marked(this.bookmark.longDescription);

    if (this.markedDescription.match(/<h/g) && this.markedDescription.match(/<h/g).length > 3) {
      this.shortMarkDown = this.markedDescription.split(/<h/g, 4).join('<h');
      this.showShortMarkdown = true;
    }
  }

}
