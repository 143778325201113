<div cdkDropListGroup>
  <div
    *ngFor="let nr of rowCount | range; index as i"
    cdkDropList
    cdkDropListOrientation="horizontal"
    [id]="i"
    class="row"
    [cdkDropListData]="bookmarks"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="col-lg-4 my-3 bookmark"
      *ngFor="let bookmark of getBookmarksFromRange(i * 3)"
      cdkDrag
    >
      <div
        *ngIf="pageWidth <= 1100"
        class="example-handle d-none"
        cdkDragHandle
      ></div>
      <app-application-card
        [useFavouritingHeart]="useFavouritingHeart"
        [bookmark]="bookmark"
        (removeFavouriteEmitter)="removeItem($event)"
      ></app-application-card>
    </div>
  </div>
</div>
