import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Input() inputModel: string;
  @Output() inputModelChange = new EventEmitter<string>();
  focus: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
