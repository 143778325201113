<div class="row">
  <span class="section-title col-12">
    Contact us
  </span>
</div>

<div class="row">
  <div class="col-lg-6 col-12">
    <div class="contact-us-card">
      <div class="row mx-0 h-100">
        <div class="col-xl-6 col-12 h-100">
          <div class="contact-us-card-title">
            <div>
              We value your input.
            </div>
          </div>
          <div class="contact-us-card-description">
            Hello! Have you noticed that the intranet has been updated? Because this is the first version, we want to know your opinion and improve it as much as we can.
          </div>
          <a class="btn aps-button-outlined feedback-button" [routerLink]="['/feedback']">
            Give Feedback
          </a>
        </div>

        <div class="col-xl-6 h-100 d-xl-block d-none">
          <img class="contact-us-card-image" src="../../../assets/svgs/feedback%20250x150.svg" alt="feedback"/>
        </div>
      </div>
    </div>
  </div>
</div>
