<app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
<div class="root-container" [class.collapsed]="collapsed$ | async">
  <nav>
    <app-mobile-menu *ngIf="useMobileMenu" aria-role="menu" class="z-index-100"></app-mobile-menu>
    <app-side-bar
      [attr.aria-role]="menu"
      [theme]="(themeOption$)?.theme"
      *ngIf="!useMobileMenu"
      class="menu-container"
    ></app-side-bar>
  </nav>
  <div
    class="app-container"
    [class.margin-top-48]="
      useMobileMenu &&
      !((hideMobileMenuService.getHideStatus() | async) === true)
    "
  >
    <main>
      <router-outlet></router-outlet>
    </main>
    <app-footer
      class="footer"
      [columns]="getFooterContent()"
    ></app-footer>
  </div>
</div>
