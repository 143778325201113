import {Component, Input, OnInit} from '@angular/core';
import { ThemeOption } from 'src/app/shared/interfaces/themeOption';
import {Blog} from '../../../shared/interfaces/blog';

@Component({
  selector: 'app-promoted-news-list',
  templateUrl: './promoted-news-list.component.html',
  styleUrls: ['./promoted-news-list.component.scss']
})
export class PromotedNewsListComponent implements OnInit {
  @Input() blogs: Blog[];
  @Input() theme: ThemeOption;
  @Input() useFavouritingHeart: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
