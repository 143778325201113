import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Blog } from '../../interfaces/blog';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { ContentService } from '../content/content.service';

@Injectable({
  providedIn: 'root',
})
export class BlogsService {
  constructor(private apollo: Apollo, private contentService: ContentService) {}

  getAllBlogs(): Promise<ApolloQueryResult<Blog[]>> {
    return this.apollo.client.query({
      query: gql`
        query allBlogs {
          fetchAllBlogsByRole(
            sort: "created_at:desc"
            where: { promoted: false }
          ) {
            id
            title
            description
            content
            user: maintained_by {
              username
              email
              name
            }
            thumbnail {
              url
              name
              alternativeText
            }
            updatedAt: updated_at
            createdAt: created_at
            displayDate: display_date
          }

          fetchAllPromotedBlogsByRole(
            sort: "created_at:desc"
            where: { promoted: true }
          ) {
            id
            title
            description
            content
            user: maintained_by {
              username
              email
              name
            }
            thumbnail {
              url
              name
              alternativeText
            }
            updatedAt: updated_at
            createdAt: created_at
            displayDate: display_date
          }
        }
      `,
      fetchPolicy: 'no-cache'
    },
    );
  }

  getBlogById(blogId: number): Promise<ApolloQueryResult<any>> {
    return this.apollo.client.query<Blog>({
      query: gql`
        query blogById{
          blog(id: ${blogId}){
            title
            description
            content
            user: maintained_by{
              username
              email
              name
            }
            thumbnail{
                url
                name
            }
            createdAt: created_at
            displayDate: display_date
          }
        }
      `,
    });
  }

  filterBlogsByTitle(blogsToFilter: Blog[], titleToFilterBy: string): Blog[] {
    return blogsToFilter.filter((blog) =>
      blog.title.toLowerCase().includes(titleToFilterBy.toLowerCase())
    );
  }

  filterPromotedBlogsByTitle(
    blogsToFilter: Blog[],
    titleToFilterBy: string
  ): Blog[] {
    return blogsToFilter.filter((blog) =>
      blog.title.toLowerCase().includes(titleToFilterBy.toLowerCase())
    );
  }

  calculateRowCount(columnSize: number, arrLength: number): number {
    return this.contentService.calculateRowCount(columnSize, arrLength);
  }

  getBlogsFromRange(
    blogs: Blog[],
    startRange: number,
    columnSize: number
  ): Blog[] {
    return this.contentService.getItemsFromRange(blogs, startRange, columnSize);
  }
}
