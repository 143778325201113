import { Component, Input } from '@angular/core';
import { FooterColumn } from 'src/app/core/interfaces/footer';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  @Input() columns: FooterColumn[] = [];
  currentYear = new Date().getFullYear();
}
