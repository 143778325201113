import { favouritesRoute } from './favourites.routes';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import * as container from './containers';

@NgModule({
  declarations: [container.DashboardComponent],
  imports: [CommonModule, SharedModule, RouterModule.forChild(favouritesRoute)],
})
export class FavouritesModule {}
