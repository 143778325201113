<div *ngIf="blog" class="container pt-3">
  <app-breadcrumb color="theme" [items]="breadcrumbs"></app-breadcrumb>
  <div class="row justify-content-center">
    <div class="col-md-8 col-11">
      <h1 class="row d-flex justify-content-center page-title">
        {{ blog.title }}
      </h1>
      <div class="blog-thumbnail-container row mb-0" *ngIf="blog.thumbnail">
        <img
          class="blog-thumbnail"
          [src]="contentService.getImageURL(blog.thumbnail)"
          alt="Blog thumbnail..."
        />
      </div>
      <div
        class="blog-content-container row justify-content-center"
        [class.imageless-blog]="!blog.thumbnail"
      >
        <div class="col-md-9 col-11">
          <div class="created-by d-flex">
            <strong *ngIf="blog.user" class="pr-2">{{ blog.user.name }}</strong>
            <span *ngIf="blog.displayDate">{{
              contentService.getDateFromString(blog.createdAt)
            }}</span>
          </div>
          <div
            class="ck-content blog-content"
            [innerHTML]="sanitizer.bypassSecurityTrustHtml(blog.content)"
          ></div>
        </div>
      </div>
      <button
        class="btn aps-button text-uppercase back-button"
        (click)="location.back()"
      >
        <span class="d-flex justify-content-center align-items-center">
          <span class="material-icons-outlined text-white"> arrow_back </span>
          Back to overview
        </span>
      </button>
    </div>
  </div>
</div>
