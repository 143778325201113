import { Component, Input } from '@angular/core';
import { Breadcrumbs } from 'src/app/shared/interfaces/breadcrumbs';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent {
  @Input() items: Breadcrumbs[] = [];
  @Input() color = 'primary';
  @Input() padding = 'pb-5';
}
