import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Bookmark} from '../../interfaces/bookmark';
import {ApplicationService} from '../../services/application/application.service';
import {appConstants} from '../../../constants';
import {FavouritesService} from '../../services/favourites/favourites.service';

@Component({
  selector: 'app-drag-and-drop-tools',
  templateUrl: './drag-and-drop-tools.component.html',
  styleUrls: ['./drag-and-drop-tools.component.scss']
})
export class DragAndDropComponent implements OnInit {
  @Input() bookmarks: Bookmark[];
  @Input() rowCount: number;
  @Input() useFavouritingHeart = false;
  @Output() removeItemEmitter = new EventEmitter<number>();
  pageWidth = window.innerWidth;

  constructor(private applicationService: ApplicationService, private favouritesService: FavouritesService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.pageWidth = window.innerWidth;
  }

  ngOnInit(): void {
  }

  getBookmarksFromRange(startRange: number): Bookmark[]{
    return this.applicationService.getApplicationsFromRange(this.bookmarks, startRange, appConstants.columnSize);
  }

  drop(event: CdkDragDrop<number[]>): void {
    const previousContainerId = Number(event.previousContainer.id);
    const nextContainerId = Number(event.container.id);
    const fromIndex = event.previousIndex + (appConstants.columnSize * previousContainerId);
    let toIndex;

    if (event.previousContainer === event.container) {
      toIndex = event.currentIndex + (appConstants.columnSize * nextContainerId);
      moveItemInArray(event.container.data, fromIndex, toIndex);
    } else {
      toIndex = Math.max(event.currentIndex + (appConstants.columnSize * nextContainerId) - 1, 0);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        fromIndex,
        toIndex);
    }

    if (fromIndex !== toIndex){
      const bookmarkToMoveNr = this.bookmarks[toIndex].id;
      const bookmarkLeftNr = this.bookmarks[toIndex - 1] ? this.bookmarks[toIndex - 1].id : -1;
      const bookmarkRightNr = this.bookmarks[toIndex + 1] ? this.bookmarks[toIndex + 1].id : -1;

      this.favouritesService.reorderFavourite(bookmarkToMoveNr, bookmarkLeftNr, bookmarkRightNr);
    }
  }

  removeItem(toRemoveId: number): void{
    this.removeItemEmitter.emit(toRemoveId);
  }

}
