<!-- Modal -->
<div class="modal fade" id="requestModal" tabindex="-1" role="dialog" aria-labelledby="requestModal" aria-hidden="true">
  <div id="top-menu-space-holder" class="d-block d-md-none"></div>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header text-white">
        <div class="modal-title">
          <p class="app-name small">{{bookmark.title}}</p>
          <h1 class="modal-title" id="requestModalLabel">Request access</h1>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span class="material-icons-outlined" style="color: #FFFFFF!important;" aria-hidden="true">close</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="form-group col-9">
            <label class="font-weight-bold" for="requestAccess">APS employee's name </label>
            <input class="form-control" id="requestAccess" placeholder="Enter full name" [(ngModel)]="textInput" (keydown.enter)="addName(textInput)">
          </div>
          <div class="col-3 form-group d-flex align-items-end">
            <button type="button" class="btn aps-button w-100" (click)="addName(textInput)">
              Add
            </button>
          </div>
        </div>

        <div class="row">
          <ul *ngIf="namesArray.length > 0; else noNames" class="list-group list-group-flush col">
            <li *ngFor="let name of namesArray; index as i" class="list-group-item col border-bottom">
              <span class="col-10">
                {{name}}
              </span>
              <button type="button" class="close col-2" aria-label="Close" (click)="removeName(i)">
                <span class="material-icons-outlined" aria-hidden="true">delete_outline</span>
              </button>
            </li>
          </ul>
          <ng-template #noNames class="text-muted">
            <div class="col">
              No names added yet
            </div>
          </ng-template>
        </div>

      </div>
      <div class="modal-footer d-flex justify-content-between">
        <button type="button" class="btn aps-button-outlined text-uppercase d-flex justify-content-center align-items-center" data-dismiss="modal" id="close-modal-button" (click)="clearAllNames()">
          Cancel
          <span class="material-icons-outlined" aria-hidden="true">close</span>
        </button>
        <button id="modalRequestAccessButton" type="button" class="btn aps-button text-uppercase" (click)="requestAccess()">Request access</button>
        <ng-template #unauthorized>
          Unauthorized! <u (click)="authService.redirectToLoginPage()" class="hoverable-text under">Click here to log in!</u>
        </ng-template>
      </div>
    </div>
  </div>
</div>
