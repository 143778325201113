<div
  class="menu-container"
  *ngIf="theme as theme"
  [class.collapsed]="collapsed$ | async"
  role="menu"
>
  <div class="menu-toggle-container">
    <div class="menu-toggle">
      <span
        class="material-icons-outlined text-white"
        tabindex="0"
        (click)="toggleCollapse()"
        (keydown.enter)="toggleCollapse()"
        aria-label="Expand/Collapse the sidebar"
        role="menuitem"
      >
        {{
          (collapsed$ | async) ? "keyboard_arrow_right" : "keyboard_arrow_left"
        }}
      </span>
    </div>
  </div>

  <div class="space-logo">
    <a href="/" tabindex="-1" role="menuitem">
      <img
        class="landscape"
        [src]="getImageURL(theme?.myTheme?.logo)"
        alt="APS logo"
      />
    </a>
  </div>
  <ng-template #loading>
    <div class="d-flex justify-content-center">
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
  <ng-container *ngIf="sideBarItems$ | async as sideBarItems; else loading">
    <a
      *ngFor="let item of sideBarItems; index as i"
      class="feature-nav"
      [class.selected]="router.url === item.path"
      [routerLink]="item.path"
      tabindex="0" 
      role="menuitem"
    >
      <a
        *ngIf="item.iconTitle"
        class="material-icons-outlined feature-icon"
        [attr.aria-hidden]="true"
      >
        {{ item.iconTitle }}
      </a>

      <span class="feature-nav-label" [class.collapsed]="collapsed$ | async">{{
        item.name
      }}</span>
      <span
        class="feature-nav-tooltip"
        [class.collapsed]="collapsed$ | async"
        >{{ item.name }}</span
      >
    </a>
    <div class="logout-nav">
      <div
        *ngIf="useFeedback$"
        class="feature-nav"
        [routerLink]="['/feedback']"
        [class.selected]="router.url === '/feedback'"
      >
        <div class="material-icons-outlined feature-icon">rate_review</div>
        <span class="feature-nav-label">Give Feedback</span>
        <span class="feature-nav-tooltip">Give Feedback</span>
      </div>
      <hr class="logout-border" />
      <div
        class="feature-nav"
        (click)="logout()"
        tabindex="0"
        (keydown.enter)="logout()"
      >
        <div
          class="material-icons-outlined feature-icon"
          [attr.aria-hidden]="true"
        >
          logout
        </div>
        <span class="feature-nav-label">Logout</span>
        <span class="feature-nav-tooltip">Logout</span>
      </div>
    </div>
  </ng-container>
</div>
