<ng-template #loading>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
<div *ngIf="bookmark; else loading">
  <app-request-access-modal [bookmark]="bookmark"></app-request-access-modal>
  <div class="top-bar"></div>
  <div class="container">
    <div
      *ngIf="!bookmark"
      class="d-flex justify-content-center align-items-center h-100"
    >
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div *ngIf="bookmark" class="pt-3">
      <div class="category-breadcrumb">
        <div class="col-auto small category-crumb">
          <app-breadcrumb
            [items]="breadcrumbs"
            color="white"
            padding="pb-0"
          ></app-breadcrumb>
        </div>
      </div>

      <div class="row title-row">
        <!--      Avatar-->
        <div class="col-xl-2 col-md-3 col-sm-4 col-6">
          <div
            class="image-container d-flex justify-content-center align-items-center"
          >
            <app-avatar
              [thumbnail]="bookmark.thumbnail"
              [iconTitle]="bookmark.iconTitle"
            ></app-avatar>
          </div>
        </div>

        <!--      Name & Category-->
        <div class="col-xl-7 col-md-6 col-sm-4 col-6 d-flex flex-row">
          <div class="title-and-category">
            <h1 class="row title display-4">
              {{ bookmark.title }}
            </h1>
            <h2 class="row category">
              {{ bookmark.category.title }}
            </h2>
          </div>
        </div>

        <!--      Favourite-->
        <div class="col-1 d-sm-flex justify-content-center d-none">
          <div class="favourite-container" (click)="favouriteClicked($event)" (keydown.enter)="favouriteClicked($event)" tabindex="0" >
            <span class="material-icons-filled">
              {{ isFavourite ? "favorite" : "favorite_bordered" }}
            </span>
          </div>
        </div>

        <!--      CTA button -->
        <div class="col-2 d-none d-sm-flex">
          <button
            class="btn aps-button align-bot redirect-button d-flex align-items-center justify-content-center"
            (click)="openPage(bookmark.url)"
          >
            <div class="color-white d-lg-block d-none">OPEN&nbsp;THE&nbsp;TOOL</div>
            <em class="material-icons-filled color-white button-icon"
              >open_in_new</em
            >
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-5">
          <div class="card extra-info">
            <h1>Things to know</h1>
            <ul class="list-group list-group-flush margin-bottom-24">
              <li
                class="list-group-item d-flex align-items-center d-flex row"
                *ngFor="let icon of bookmark.icons"
              >
                <em
                  class="material-icons-outlined list-icon col-1"
                  [attr.style]="'color:' + icon.colour + ' !important'"
                >
                  {{ icon.code }}
                </em>
                <span class="col-11 icon-description">
                  <strong>
                    {{ icon.name }}
                  </strong>
                  <br />
                  {{ icon.description }}
                </span>
              </li>

              <li
                *ngIf="bookmark.demoMode && bookmark.caseStudies"
                class="list-group-item d-flex align-items-center"
              >
                <em class="material-icons-outlined list-icon">library_books</em>
                <div class="col-11 icon-description">
                  <strong
                    class="font-weight-bolder d-flex"
                    *ngIf="bookmark.caseStudiesTitle"
                    >{{ bookmark.caseStudiesTitle }}</strong
                  >
                  <div
                    class="list-content"
                    [innerHTML]="bookmark.caseStudies"
                  ></div>
                </div>
              </li>

              <li
                *ngIf="bookmark.samId"
                class="list-group-item d-flex align-items-center"
              >
                <em class="material-icons-outlined list-icon">fingerprint</em>
                <div class="col-11 icon-description">
                  <strong class="font-weight-bolder d-flex">SAM ID</strong>
                  <div class="list-content">{{ bookmark.samId }}</div>
                </div>
              </li>

              <li
                *ngIf="bookmark.user && bookmark.user.name"
                class="list-group-item d-flex align-items-center"
              >
                <em class="material-icons-outlined list-icon"
                  >assignment_ind</em
                >
                <div class="col-11 icon-description">
                  <strong class="font-weight-bolder d-flex">App owner</strong>
                  <div class="list-content">{{ bookmark.user.name }}</div>
                </div>
              </li>

              <li
                *ngIf="bookmark.manuals && bookmark.manuals.length === 1"
                class="list-group-item d-flex align-items-center"
              >
                <em class="material-icons-outlined list-icon">article</em>
                This document contains a manual. Click on the button below to
                view it!
              </li>

              <li
                *ngIf="bookmark.manuals && bookmark.manuals.length > 1"
                class="list-group-item d-flex align-items-center"
              >
                <em class="material-icons-outlined list-icon">article</em>
                This document contains multiple manuals. Click on the dropdown
                below to select a manual!
              </li>

              <li
                *ngIf="
                  bookmark.icons.length === 0 &&
                  !bookmark.samId &&
                  !bookmark.user &&
                  !bookmark.demoMode &&
                  !bookmark.caseStudies &&
                  bookmark.manuals.length === 0
                "
                class="list-group-item d-flex align-items-center text-muted"
              >
                <em>No additional information added yet</em>
              </li>
            </ul>
            <div class="row justify-content-end">
              <div class="col-xl-6 col-12">
                <!-- Button trigger modal -->
                <button
                  *ngIf="!bookmark.demoMode"
                  id="requestAccessButton"
                  class="btn aps-button-outlined w-100 text-uppercase"
                  data-toggle="modal"
                  data-target="#requestModal"
                >
                  Request access
                </button>
              </div>
            </div>
          </div>
          <div
            class="card"
            *ngIf="bookmark.manuals && bookmark.manuals.length > 0"
          >
            <h1>Manuals</h1>
            <div class="d-flex manual" *ngFor="let manual of bookmark.manuals">
              <span class="material-icons-outlined mr-3 manual-icon">
                open_in_new
              </span>
              <span
                class="d-flex flex-column manual-text"
                (click)="openManual(manual)"
              >
                <span class="manual-name">
                  {{ manual.name }}
                </span>
                <span>
                  Uploaded at
                  {{
                    contentService.getDateFromString(manual.updatedAt, {day: 'numeric', month: 'long', year: 'numeric'}).toLowerCase() | titlecase
                  }}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-7">
          <div class="card description">
            <app-about-the-app
              *ngIf="bookmark.longDescription; else noDescription"
              [bookmark]="bookmark"
            ></app-about-the-app>
            <ng-template #noDescription>
              <h2><em>No description added yet.</em></h2>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
