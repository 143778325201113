import { toolsRoute } from './tools.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import * as container from './containers';
import * as component from './components';

@NgModule({
  declarations: [
    component.ContactUsComponent,
    component.RequestAccessModalComponent,
    container.AllAppsComponent,
    container.AppDetailsComponent,
    container.AboutTheAppComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(toolsRoute),
    FormsModule,
  ],
})
export class ToolsModule {}
