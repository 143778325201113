import { AuthService } from 'src/app/core/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { BlogsService } from 'src/app/shared/services/blogs/blogs.service';
import { ThemeOptionsService } from 'src/app/shared/services/theme-options/theme-options.service';
import LABELS from 'src/app/shared/data/ui-labels.json';
import { Blog } from 'src/app/shared/interfaces/blog';
import { appConstants } from 'src/app/constants';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Bookmark } from 'src/app/shared/interfaces/bookmark';
import { FavouritesService } from 'src/app/shared/services/favourites/favourites.service';
import { FavouriteStoriesService } from 'src/app/shared/services/favourite-stories/favourite-stories.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  headerName: string;
  news = LABELS.UI.NEWS;
  newsTitle = LABELS.UI.HOME_NEWS_TITLE;
  newsButton = LABELS.UI.HOME_NEWS_OVERVIEW;
  blogResponse$ = this.blogService.getAllBlogs();
  themeOption$ = this.themeOptionsService.getThemeOption();
  searchInput: string;
  favourites: Bookmark[] = [];
  limitedFavourites: Bookmark[] = [];
  favouriteStories: Blog[] = [];
  filteredFavouriteStories: Blog[];
  limitedFavouriteStories: Blog[] = [];
  loadingTools: boolean = true;
  loadingStories: boolean = true;

  constructor(
    private favouritesService: FavouritesService,
    private favouriteStoriesService: FavouriteStoriesService,
    private blogService: BlogsService,
    private themeOptionsService: ThemeOptionsService,
    public authService: AuthService,
    private applicationService: ApplicationService
  ) {}

  ngOnInit(): void {
    this.getFavourites();
    this.getFavouriteStories();
  }

  getBlogsFromThemeOptions(limit?: number): Blog[] {
    return this.favouriteStories.slice(0, limit || 3);
  }

  getRowCount(length: number): number {
    return this.applicationService.calculateRowCount(appConstants.columnSize, length);
  }

  getFavourites(): void {
    this.favouritesService
      .getFavourites()
      .valueChanges.subscribe(({ data, loading, error }) => {
        if (error) {
          console.error(error);
        } else {  
          this.loadingTools = loading;
          const favourites = data.myFavourites.map((f) => f.bookmark) || [];
          this.favourites = JSON.parse(JSON.stringify(favourites));
          this.limitedFavourites = favourites.slice(0, 6);
        }
      });
  }

  getFavouriteStories(): void {
    this.favouriteStoriesService
      .myFavouriteStories()
      .valueChanges.subscribe(async ({ data, loading, error }) => {
        if (error) {
          console.error(error);
        } else {
          this.loadingStories = loading;
          const favouriteStories =
            data.myFavouriteStories.map((f) => f.blog) || [];
          this.favouriteStories = JSON.parse(JSON.stringify(favouriteStories));
          this.filteredFavouriteStories = JSON.parse(
            JSON.stringify(favouriteStories)
          );
          this.limitedFavouriteStories = JSON.parse(
            JSON.stringify(favouriteStories)
          );
          this.setStoriesLimit();
        }
      });
  }

  async setStoriesLimit() {
    let theme: any = [];
    theme = (await this.themeOption$)?.theme;
    this.limitedFavouriteStories = theme?.myTheme?.numberOfStories
      ? this.limitedFavouriteStories.slice(
          0,
          theme.myTheme.numberOfStories
        )
      : this.limitedFavouriteStories.slice(0, 3);
  }
}
