import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const displayFreshStatusWidget = () => {

  const useFreshStatusWidget = JSON.parse(environment.useFreshStatusWidget)

  if (useFreshStatusWidget) {
    const freshStatusFetch = document.createElement('script');
    
    freshStatusFetch.src = 'https://cdn.freshstatus.io/widget/index.js';
    freshStatusFetch.type = 'module';

    document.head.appendChild(freshStatusFetch);
  }
}

displayFreshStatusWidget()

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
