import { Blog } from './../../interfaces/blog';
import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { BlogsService } from 'src/app/shared/services/blogs/blogs.service';
import { appConstants } from 'src/app/constants';
import { FavouriteStoriesService } from 'src/app/shared/services/favourite-stories/favourite-stories.service';
@Component({
  selector: 'app-drag-and-drop-stories',
  templateUrl: './drag-and-drop-stories.component.html',
  styles: ['']
})
export class DragAndDropStoriesComponent implements OnInit {
  @Input() blogs: Blog[];
  @Input() rowCount: number;
  @Input() useFavouritingHeart = false;
  @Output() removeItemEmitter = new EventEmitter<number>();
  constants = appConstants;
  pageWidth = window.innerWidth;

  constructor(private blogsService: BlogsService, private favouriteStoriesService: FavouriteStoriesService) { }

  ngOnInit(): void {
  }

  getBlogsFromRange(startRange: number): Blog[]{
    return this.blogsService.getBlogsFromRange(this.blogs, startRange, this.constants.columnSize);
  }
  drop(event: CdkDragDrop<number[]>): void {
    const previousContainerId = Number(event.previousContainer.id);
    const nextContainerId = Number(event.container.id);
    const fromIndex = event.previousIndex + (this.constants.columnSize * previousContainerId);
    let toIndex;

    if (event.previousContainer === event.container) {
      toIndex = event.currentIndex + (this.constants.columnSize * nextContainerId);
      moveItemInArray(event.container.data, fromIndex, toIndex);
    } else {
      toIndex = Math.max(event.currentIndex + (this.constants.columnSize * nextContainerId) - 1, 0);
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        fromIndex,
        toIndex);
    }

    if (fromIndex !== toIndex){
      const bookmarkToMoveNr = this.blogs[toIndex].id;
      const bookmarkLeftNr = this.blogs[toIndex - 1] ? this.blogs[toIndex - 1].id : -1;
      const bookmarkRightNr = this.blogs[toIndex + 1] ? this.blogs[toIndex + 1].id : -1;

      this.favouriteStoriesService.reorderFavouriteStory(bookmarkToMoveNr, bookmarkLeftNr, bookmarkRightNr);
    }
  }

  removeItem(toRemoveId: number): void{
    this.removeItemEmitter.emit(toRemoveId);
  }

}
