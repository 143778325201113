import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Category } from '../../interfaces/category';
import { ApolloQueryResult } from '@apollo/client/core';
import { Bookmark } from '../../interfaces/bookmark';
import { BookmarkType } from '../../interfaces/bookmarkType';
import { ContentService } from '../content/content.service';
import { Manual } from '../../interfaces/manual';

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  constructor(private apollo: Apollo, private contentService: ContentService) {}

  getAllApps(): Promise<ApolloQueryResult<Bookmark[]>> {
    return this.apollo.client.query<Bookmark[]>({
      query: gql`
        query allBookmarks {
          fetchAllBookmarksByRole {
            id
            title
            shortDescription: short_description
            longDescription: long_description
            url: URL
            createdAt: created_at
            iconTitle: icon_title
            requestMail: request_mail
            samId: sam_id
            demoMode: demo_mode
            caseStudies: case_studies
            icons {
              name
              code
              description
              colour
            }
            category {
              title
            }
            caseStudiesTitle: case_studies_title
            user: maintained_by {
              username
              email
              name
            }
            thumbnail {
              url
              name
              alternativeText
            }
            manuals {
              id
              name
              hash
              ext
              updatedAt: updated_at
            }
            bookmarkType: bookmark_type {
              title
            }
          }
          categories {
            title
          }
          bookmarkTypes {
            title
          }
        }
      `,
    });
  }

  getBookmarkById(bookmarkId: number): Promise<ApolloQueryResult<any>> {
    return this.apollo.client.query<Bookmark>({
      query: gql`
        query bookmarkById{
          bookmark(id: ${bookmarkId}){
            id
            title
            shortDescription: short_description
            longDescription: long_description
            url: URL
            createdAt: created_at
            iconTitle: icon_title
            requestMail: request_mail
            samId: sam_id
            demoMode: demo_mode
            caseStudies: case_studies
            icons {
              name
              code
              description
              colour
            }
            category {
              title
            }
            caseStudiesTitle: case_studies_title
            user: maintained_by {
              username
              email
              name
            }
            thumbnail {
              url
              name
              alternativeText
            }
            manuals {
              id
              name
              hash
              ext
              updatedAt: updated_at
            }
            bookmarkType: bookmark_type {
              title
            }
          }
          categories {
            title
          }
          bookmarkTypes {
            title
          }
        }
      `,
    });
  }

  generateSignedURL(manual: Manual): Promise<ApolloQueryResult<string>> {
    return this.apollo.client.query<string>({
      query: gql`
        query generateSignedUrl($hash: String, $ext: String, $id: ID) {
          url: generateSignedUrl(
            documentData: { hash: $hash, ext: $ext, id: $id }
          )
        }
      `,
      variables: {
        hash: manual.hash,
        ext: manual.ext,
        id: manual.id,
      },
    });
  }

  filterApplicationsByTitle(bookmarksToFilter: Bookmark[], titleToFilterBy: string): Bookmark[]{
    return bookmarksToFilter.filter(bookmark => bookmark.title.toLowerCase().includes(titleToFilterBy.toLowerCase()));
  }

  filterApplications(
    bookmarkType: BookmarkType,
    bookmarks: Bookmark[],
    categoryFilter: string = '',
    titleToFilterBy: string = ''
  ): Category[] {
    const bookmarksFilteredByType = this.filterApplicationsByType(
      bookmarks,
      bookmarkType
    );
    const bookmarksFilteredByCategory = this.filterApplicationsByCategory(
      bookmarksFilteredByType,
      categoryFilter
    );
    const bookmarksFilteredByTitle = this.filterApplicationsByTitle(
      bookmarksFilteredByCategory,
      titleToFilterBy
    );

    const filteredCategories = [
      ...new Set(bookmarksFilteredByTitle.map((b) => b.category.title)),
    ];

    return filteredCategories.map((c) => {
      const category: Category = {
        title: c,
        bookmarks: bookmarksFilteredByTitle.filter(
          (bookmark) => bookmark.category.title === c
        ),
      };

      return category;
    });
  }

  calculateRowCount(columnSize: number, arrLength: number): number {
    return this.contentService.calculateRowCount(columnSize, arrLength);
  }

  getApplicationsFromRange(
    bookmarks: Bookmark[],
    startRange: number,
    columnSize: number
  ): Bookmark[] {
    return this.contentService.getItemsFromRange(
      bookmarks,
      startRange,
      columnSize
    );
  }

  getApplicationById(bookmarks: Bookmark[], bookmarkId: number): Bookmark {
    return bookmarks.find((b) => Number(b.id) === bookmarkId);
  }

  private filterApplicationsByType(bookmarks: Bookmark[], bookmarkType: BookmarkType): Bookmark[]{
    return bookmarks.filter(bookmark => bookmark.bookmarkType.title.toLowerCase() === bookmarkType.title.toLowerCase());
  }

  private filterApplicationsByCategory(bookmarksToFilter: Bookmark[], categoryFilter: string): Bookmark[]{
    return categoryFilter ?
      bookmarksToFilter.filter(bookmark => bookmark.category.title.toLowerCase() === categoryFilter.toLowerCase()) : bookmarksToFilter;
  }
}
