import { homeRoutes } from './home.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';;
import { RouterModule } from '@angular/router';

import * as container from './containers';


@NgModule({
  declarations: [
    container.HomepageComponent
  ],
  imports: [
    RouterModule.forChild(homeRoutes),
    CommonModule,
    SharedModule
  ]
})
export class HomeModule { }
