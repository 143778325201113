import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HideMobileMenuService {
  hideMobileMenu = new BehaviorSubject<boolean>(false);

  constructor() { }

  setHideStatus(newStatus: boolean): void {
    if (this.hideMobileMenu.getValue() !== newStatus) {
      this.hideMobileMenu.next(newStatus);
    }
  }

  getHideStatus(): Observable<boolean>{
    return this.hideMobileMenu.asObservable();
  }
}
