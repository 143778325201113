import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Thumbnail} from '../../interfaces/thumbnail';
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor() { }

  calculateRowCount(columnSize: number, arrLength: number): number {
    return Math.ceil(arrLength / columnSize);
  }

  getItemsFromRange(items: any[], startRange: number, columnSize: number): any[] {
    return items.slice(startRange, startRange + columnSize);
  }

  getImageURL(thumbnail: Thumbnail): string{
    if (!thumbnail){
      return 'https://via.placeholder.com/300x150';
    }
    return environment.production ? thumbnail.url : environment.baseUrl + thumbnail.url;
  }

  getManualURL(url: string): string {
    return environment.production ? url : environment.baseUrl + url;
  }

  getDateFromString(createdAt: string, options?: DateTimeFormatOptions): string{
    const defaultOptions: DateTimeFormatOptions = {day: 'numeric', month: 'short', year: 'numeric'};
    return new Date(createdAt).toLocaleDateString('en-GB', options || defaultOptions).toUpperCase();
  }
}
