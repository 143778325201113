import { Injectable, ErrorHandler} from '@angular/core';
import * as Sentry from '@sentry/browser';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SentryErrorHandlerService implements ErrorHandler {

  constructor(private authService: AuthService) {
    Sentry.init({
      dsn: environment.sentryDSN,
      beforeSend: (event, hint): any => {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user: {
              name: authService.getUsername(),
              email: authService.getEmail()
            }
          });
        }
        return event;
      },
    });
  }

  handleError(error): void {
    Sentry.captureException(error.originalError || error);
  }
}
