import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  updateLocalStorage(key: string, data: any): void{
    localStorage[key] = JSON.stringify(data);
  }

  removeKeyFromLocalStorage(key: string): void{
    localStorage.removeItem(key);
  }

  getLocalDataByKey(key: string): any{
    return localStorage[key] ? JSON.parse(localStorage[key]) : '';
  }
}
