import { UrlSegment } from '@angular/router';
export interface ThemeOption {
  theme: Theme;
  headerName: string;
  headerBgColor: string;
  headerTitleColor: string;
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
  usLocale: boolean;
  hideStories: boolean;
  yourFavouriteApp: string;
  storiesTitle: string;
  backgroundColor: string;
  numberOfStories: number;
  logo: Logo;
  favicon: Logo;
}
export interface Logo {
  url: string;
  alternativeText: string;
}
export interface Theme {
  myTheme: ThemeOption;
}
