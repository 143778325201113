<footer class="footer">
  <div class="container pt-6 top-footer">
    <div class="row pb-4 color--blue-dark">
      <ng-container *ngIf="columns">
        <div class="col-md-3 py-md-0 py-3" *ngFor="let item of columns">
          <p class="font-weight-bold">{{ item.title }}</p>
          <p class="sub-text" [innerHTML]="item.content"></p>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="container-fluid color-primary">
    <div class="container">
      <div class="row py-5 align-items-center" *ngIf="columns">
        <div class="col-md-4 pb-5 pt-4 text-white text-md-left text-center">
          <small>&copy; {{ currentYear }} APS Group</small>
        </div>
      </div>

      <div class="row py-5" *ngIf="!columns">
        <p class="pt-4 footer-error-text">A technical error is preventing the standard footer from being displayed. Please ensure a default footer is set. If it is set, reach out for technical support.</p>
      </div>
    </div>
  </div>
</footer>
