import {Component, OnInit, TemplateRef} from '@angular/core';
import {ToastService} from '../../services/toast/toast.service';

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.scss'],
  // eslint-disable-next-line @typescript-eslint/naming-convention, @angular-eslint/no-host-metadata-property
  host: {'[class.ngb-toasts]': 'true'}
})
export class ToastsComponent implements OnInit {

  constructor(public toastService: ToastService) {}

  ngOnInit(): void {
  }

  isTemplate(toast): boolean { return toast.textOrTpl instanceof TemplateRef; }
}
