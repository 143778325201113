<div
  class="card m-0"
  [ngClass]="promoted ? 'blog-card-promoted' : 'blog-card'"
  (click)="redirectToBlog(blog)"
  (keydown.enter)="redirectToBlog(blog)"
  tabindex="0"
>
  <div
    *ngIf="blog.thumbnail"
    class="card-img-top d-flex justify-content-center"
  >
    <img
      class="blog-thumbnail"
      [src]="getImageUrl()"
      [alt]="blog.thumbnail?.alternativeText ?? '...'"
    />
  </div>
  <div class="card-body d-flex flex-column">
    <div class="header mb-0" [ngClass]="!blog.thumbnail ? 'd-flex' : ''">
      <h2 class="card-title m-0 font-weight-bold" [class.big-title]="!blog.thumbnail">{{ blog.title | truncate: [maxCharactersBlogCardTitle] }}</h2>
      <ng-container *ngIf="useFavouritingHeart">
        <div class="favourite-item" role="button" tabindex="0" (keydown.enter)="favouriteClicked($event)" (click)="favouriteClicked($event)" [ngClass]="blog.thumbnail ? 'favourite-icon-absolute' : 'favourite-icon-flex'">
          <div class="icon-container">
            <div class="material-icons-filled bookmark-heart-icon">
              {{isFavourite ? 'favorite' : 'favorite_bordered'}}
            </div>
          </div>
        </div>
      </ng-container>
    </div>

    <p
      *ngIf="!blog.thumbnail"
      class="card-text"
      [innerHTML]="blog.description | truncate: [maxCharacterSizeBlogCardText]"
    ></p>
    
    <div *ngIf="blog.user || blog.displayDate" class="blog-footer d-flex">
      <p *ngIf="blog.user" class="m-0 pr-2 font-weight-bold">
        {{ blog.user.name }}
      </p>
      <p *ngIf="blog.displayDate" class="m-0 pr-2">
        {{ contentService.getDateFromString(blog.createdAt) }}
      </p>
    </div>
  </div>
</div>
