import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FOOTERS } from '../../graphql/footers.graphql';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(private readonly apollo: Apollo) {}

  public getFooterColumns(key: string): Observable<{contentColumns: any[]}> {
    return this.apollo
      .query<{ footers }>({
        query: FOOTERS,
        variables: {key},
      })
      .pipe(
        map((res) => {
          if (!res.data?.footers) {
            throw new Error(`Could not find footers for keys: ${[key]}`);
          }
          const footers = res.data.footers;
          return {
            contentColumns: footers.find((footer) => footer.key === key)?.columns
          };
        })
      );
  }
}

