import { Observable } from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {Bookmark} from '../../../shared/interfaces/bookmark';
import {FavouritesService} from '../../../shared/services/favourites/favourites.service';
import {ApplicationService} from '../../../shared/services/application/application.service';
import {ContentService} from '../../../shared/services/content/content.service';
import {Manual} from '../../../shared/interfaces/manual';
import { Breadcrumbs } from 'src/app/shared/interfaces/breadcrumbs';

@Component({
  selector: 'app-app-details',
  templateUrl: './app-details.component.html',
  styleUrls: ['./app-details.component.scss']
})
export class AppDetailsComponent implements OnInit {
  bookmarkSlug: string;
  bookmark: Bookmark;
  loading = true;
  isFavourite = false;
  breadcrumbs: Breadcrumbs[] = [];

  constructor(private route: ActivatedRoute, private applicationService: ApplicationService,
              private router: Router, private favouritesService: FavouritesService,
              private contentService: ContentService) { }

  ngOnInit(): void {
    this.getBookmarkSlug().subscribe((params) => {
      const slug = params.slug;
      const lastIndexOfStripe = slug.lastIndexOf('-');
      const bookmarkId = slug.slice(lastIndexOfStripe + 1, slug.length);
      this.getBookmarkById(bookmarkId);
    });

    this.breadcrumbs = [
      {
        label: 'Home',
        slug: '/home',
      },
      {
        label: 'All Tools',
        slug: '/tools',
      },
    ];
  }

  getBookmarkSlug(): Observable<Params> {
    return this.route.params;
  }

  getBookmarkById(bookmarkId: number): void {
    this.applicationService.getBookmarkById(bookmarkId).then((result: any) => {
      this.bookmark = JSON.parse(JSON.stringify(result.data.bookmark));
      this.breadcrumbs.push({
        label: this.bookmark.title,
        slug: '/',
      });
    });
  }

  getBookmarkTitleFromSlug(slug: string): string{
    const regex = /-/gi;
    const lastIndexOfStripe = slug.lastIndexOf('-');
    return slug.replace(regex, ' ').slice(0, lastIndexOfStripe);
  }

  getBookmarkIdFromSlug(slug: string): string{
    const lastIndexOfStripe = slug.lastIndexOf('-');
    return slug.slice(lastIndexOfStripe + 1, slug.length);
  }

  openPage(url: string): void{
    window.open(url);
  }

  openManual(manual: Manual): void {
    this.applicationService.generateSignedURL(manual).then((result: any) => {
      window.open(this.contentService.getManualURL(result.data.url));
    }).catch(e => {
      console.error(e);
    });
  }

  favouriteClicked(event: Event): void{
    event.stopPropagation();
    if (this.isFavourite){
      this.isFavourite = false;
      this.favouritesService.removeFavourite(this.bookmark.id);
    } else {
      this.isFavourite = true;
      this.favouritesService.addFavourite(this.bookmark.id);
    }
  }

  checkIfFavourite(): void{
    this.favouritesService.checkIfFavourite(this.bookmark).then(result => {
      this.isFavourite = result;
    });
  }
}
