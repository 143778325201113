import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService){}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.authService.checkIfLoggedIn()
      .then((user) => {
        if (!user){
          this.authService.logout().then(res => {
            this.authService.redirectToLoginPage(state.url);
          }).catch(err => {
            console.error(err);
          });
          return false;
        }
        return true;
      })
      .catch((err) => {
        console.error(err);
        this.authService.logout().then(res => {
          this.authService.redirectToLoginPage(state.url);
        }).catch(err2 => {
          console.error(err2);
        });
        return false;
      });
  }

}
