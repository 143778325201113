import {Component, HostListener, OnInit} from '@angular/core';
import {Category} from 'src/app/shared/interfaces/category';
import {Bookmark} from '../../../shared/interfaces/bookmark';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FavouritesService} from 'src/app/shared/services/favourites/favourites.service';
import {ApplicationService} from '../../../shared/services/application/application.service';
import {BookmarkType} from '../../../shared/interfaces/bookmarkType';
import {Breadcrumbs} from 'src/app/shared/interfaces/breadcrumbs';

@Component({
  selector: 'app-landing-page',
  templateUrl: './all-apps.component.html',
  styleUrls: ['./all-apps.component.scss'],
})
export class AllAppsComponent implements OnInit {
  fetchedBookmarks: Bookmark[] = [];
  allBookmarkTypes: BookmarkType[] = [];
  allCategories: Category[] = [];
  filteredBookmarks: Category[] = [];
  error: any;
  loading = true;
  searchInput = '';
  categoryFilter = '';
  pageWidth = window.innerWidth;
  columnSize = window.innerWidth <= 1200 ? 2 : 3;
  selectedBookmarkType: BookmarkType;
  breadcrumbs: Breadcrumbs[] = [
    {
      label: 'Home',
      slug: '/home',
    },
    {
      label: 'All Tools',
      slug: '/tools',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private favouritesService: FavouritesService,
    private applicationService: ApplicationService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.pageWidth = window.innerWidth;

    this.columnSize = window.innerWidth <= 1200 ? 2 : 3;
  }

  ngOnInit(): void {
    this.getCategoryFilter();
    this.subscribeToRouterChanges();
    this.fetchAll();
  }

  fetchAll(): void {
    this.applicationService
      .getAllApps()
      .then((result: any) => {
        this.fetchedBookmarks = result.data.fetchAllBookmarksByRole;
        this.allCategories = result.data.categories;
        this.allBookmarkTypes = result.data.bookmarkTypes;
        this.selectedBookmarkType = this.allBookmarkTypes[0];
        this.loading = result.loading;

        this.filterBookmarks();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  subscribeToRouterChanges(): void {
    this.router.events.subscribe((val: NavigationEnd) => {
      if (val instanceof NavigationEnd) {
        const path = val.urlAfterRedirects.substring(val.urlAfterRedirects.indexOf('=') + 1, val.urlAfterRedirects.length);
        this.categoryFilter = path === '/tools' ? '' : unescape(path);
        this.searchInput = '';

        this.filteredBookmarks = this.applicationService.filterApplications(this.selectedBookmarkType, this.fetchedBookmarks, this.categoryFilter);
      }
    });
  }

  getCategoryFilter(): void {
    this.route.queryParams.subscribe((params) => {
      this.categoryFilter = params.category || '';
    });
  }

  filterBookmarks(): void {
    this.filteredBookmarks = this.applicationService.filterApplications(
      this.selectedBookmarkType,
      this.fetchedBookmarks,
      this.categoryFilter,
      this.searchInput
    );
  }

  setBookmarkType(bookmarkType: BookmarkType): void {
    this.selectedBookmarkType = bookmarkType;
    this.filterBookmarks();
  }

  getBookmarkCount(bookmarkType: BookmarkType): number {
    const filteredList = this.fetchedBookmarks.filter((bt) => bt.bookmarkType.title === bookmarkType.title);
    const filteredByTitle = filteredList.filter((b) => b.title.toLowerCase().includes(this.searchInput.toLowerCase()));
    const filteredByCategory = filteredByTitle.filter((b) =>
      b.category.title.toLowerCase().includes(this.categoryFilter ? this.categoryFilter.toLowerCase() : '')
    );

    return filteredByCategory.length;
  }
}
