import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Blog} from '../../../shared/interfaces/blog';
import {BlogsService} from '../../../shared/services/blogs/blogs.service';
import {Observable} from 'rxjs';
import {ContentService} from 'src/app/shared/services/content/content.service';
import {DomSanitizer} from '@angular/platform-browser';
import {Location} from '@angular/common';
import {Breadcrumbs} from 'src/app/shared/interfaces/breadcrumbs';

@Component({
  selector: 'app-blog-page',
  templateUrl: './blog-page.component.html',
  styleUrls: ['./blog-page.component.scss'],
})
export class BlogPageComponent implements OnInit {
  blog: Blog;
  breadcrumbs: Breadcrumbs[] = [];

  constructor(
    private route: ActivatedRoute,
    private blogService: BlogsService,
    private contentService: ContentService,
    private sanitizer: DomSanitizer,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.getBlogSlug().subscribe((params) => {
      const slug = params.slug;
      const lastIndexOfStripe = slug.lastIndexOf('-');
      const blogId = slug.slice(lastIndexOfStripe + 1, slug.length);

      this.getBlogById(blogId);
    });

    this.breadcrumbs = [
      {
        label: 'Home',
        slug: '/home',
      },
      {
        label: 'Stories',
        slug: '/stories',
      },
    ];
  }

  getBlogSlug(): Observable<Params> {
    return this.route.params;
  }

  getBlogById(blogId: number): void {
    this.blogService.getBlogById(blogId).then((result: any) => {
      this.blog = JSON.parse(JSON.stringify(result.data.blog));
      this.breadcrumbs.push({
        label: this.blog.title,
        slug: '/',
      });
    });
  }
}
