import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { ThemeOption } from 'src/app/shared/interfaces/themeOption';

@Injectable({
  providedIn: 'root',
})
export class ThemeOptionsService {
  constructor(private apollo: Apollo) {}

  async getThemeOption(): Promise<ThemeOption> {
    return this.apollo.client
      .query({
        query: gql`
          query getTheme {
                myTheme {
                  headerName: header_name
                  headerBgColor: header_bg_colour
                  headerTitleColor: header_title_color
                  primaryColor: primary_color
                  secondaryColor: secondary_color
                  accentColor: accent_color
                  usLocale: us_locale
                  hideStories: hide_stories
                  yourFavouriteApp: text_your_favourite_app
                  storiesTitle: text_stories
                  backgroundColor: body_background_color
                  numberOfStories: number_of_stories
                  logo {
                    url
                    alternativeText
                  }
                  favicon {
                    url
                    alternativeText
                  }
                }
          }
        `,
        fetchPolicy: 'network-only',
      })
      .then((response: ApolloQueryResult<any>) => ({
        theme: response.data,
      }))
      .catch((err) => {
        console.error(err);
        return null;
      });
  }
}
