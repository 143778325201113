import {Thumbnail} from './thumbnail';
import {User} from './user';

export interface Blog {
  id: number;
  title: string;
  description: string;
  content: string;
  user?: User;
  createdAt: string;
  updatedAt: string;
  thumbnail: Thumbnail;
  createdBy: User;
  displayDate: boolean;
}
