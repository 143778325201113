import { storiesRoute } from './stories.routes';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';

import * as container from './containers';
import * as component from './components';


@NgModule({
  declarations: [
    component.PromotedNewsListComponent,
    container.BlogPageComponent,
    container.BlogsComponent,
    container.BlogsListComponent
  ],
  imports: [
    RouterModule.forChild(storiesRoute),
    CommonModule,
    SharedModule
  ]
})
export class StoriesModule { }
