import { ThemeOption } from 'src/app/shared/interfaces/themeOption';
import { ThemeOptionsService } from 'src/app/shared/services/theme-options/theme-options.service';
import { FavouriteStoriesService } from '../../../shared/services/favourite-stories/favourite-stories.service';
import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/shared/services/application/application.service';
import { Bookmark } from 'src/app/shared/interfaces/bookmark';
import { FavouritesService } from 'src/app/shared/services/favourites/favourites.service';
import { appConstants } from 'src/app/constants';
import { Breadcrumbs } from 'src/app/shared/interfaces/breadcrumbs';
import { Blog } from 'src/app/shared/interfaces/blog';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  breadcrumbs: Breadcrumbs[] = [
    {
      label: 'Home',
      slug: '/home',
    },
    {
      label: 'My favourites',
      slug: '/favourites',
    },
  ];
  searchInput: string;
  theme: ThemeOption;
  favourites: Bookmark[] = [];
  filteredFavourites: Bookmark[];
  favouriteStories: Blog[] = [];
  filteredFavouriteStories: Blog[];
  loading: boolean = true;

  constructor(
    private applicationService: ApplicationService,
    private favouritesService: FavouritesService,
    private favouriteStoriesService: FavouriteStoriesService,
    private themeOptionsService: ThemeOptionsService
  ) {}

  ngOnInit(): void {
    this.getFavourites();
    this.getFavouriteStories();
    this.getThemeOption()
    this.getBreadcrumbs()
  }

  getBreadcrumbs(): Observable<Breadcrumbs[]> {
    this.themeOptionsService.getThemeOption().then((theme) => {
      if(!theme.theme.myTheme) return;
      
      const boundThemeOption = theme.theme.myTheme;
      const favouritesPage = this.breadcrumbs.findIndex(page => page.slug === '/favourites');

      if(boundThemeOption.usLocale) {
        this.breadcrumbs[favouritesPage].label = 'My favorites'
      }
    });
    
  return of(this.breadcrumbs);
}

  getFavourites(): void {
    this.favouritesService
      .getFavourites()
      .valueChanges.subscribe(({ data, loading, error }) => {
        if (error) {
          console.error(error);
        } else {
          this.loading = loading;
          const favouriteStories =
            data.myFavourites.map((f) => f.bookmark) || [];
          this.favourites = JSON.parse(JSON.stringify(favouriteStories));
          this.filteredFavourites = JSON.parse(
            JSON.stringify(favouriteStories)
          );
        }
      });
  }

  getFavouriteStories(): void {
    this.favouriteStoriesService
      .myFavouriteStories()
      .valueChanges.subscribe(({ data, loading, error }) => {
        if (error) {
          console.error(error);
        } else {
          this.loading = loading;
          const favouriteStories =
            data.myFavouriteStories.map((f) => f.blog) || [];
          this.favouriteStories = JSON.parse(JSON.stringify(favouriteStories));
          this.filteredFavouriteStories = JSON.parse(
            JSON.stringify(favouriteStories)
          );
        }
      });
  }

  getRowCount(length: number): number {
    return this.applicationService.calculateRowCount(
      appConstants.columnSize,
      length
    );
  }

  filterBookmarks(): void {
    this.filteredFavourites = this.applicationService.filterApplicationsByTitle(
      this.favourites,
      this.searchInput
    );
  }

  removeFavourite(toRemoveId: number): void {
    this.favourites = this.favourites.filter((b) => b.id !== toRemoveId);
    this.filteredFavourites = this.filteredFavourites.filter(
      (b) => b.id !== toRemoveId
    );
  }

  removeFavouriteStory(toRemoveId: number): void {
    this.favouriteStories = this.favouriteStories.filter(
      (b) => b.id !== toRemoveId
    );
    this.filteredFavouriteStories = this.filteredFavouriteStories.filter(
      (b) => b.id !== toRemoveId
    );
  }

  getThemeOption() {
    this.themeOptionsService.getThemeOption().then((theme) => {
      this.theme = theme?.theme?.myTheme;
    });
  }
}
