<div cdkDropListGroup>
  <div
    *ngFor="let nr of rowCount | range; index as i"
    cdkDropList
    cdkDropListOrientation="horizontal"
    [id]="i"
    class="row"
    [cdkDropListData]="blogs"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="col-lg-4 my-3 bookmark"
      *ngFor="let blog of getBlogsFromRange(i * 3)"
      cdkDrag
    >
      <div
        *ngIf="pageWidth <= 1100"
        class="example-handle d-none"
        cdkDragHandle
      ></div>
      <app-blog
        [blog]="blog"
        [useFavouritingHeart]="useFavouritingHeart"
        (removeFavouriteEmitter)="removeItem($event)"
      ></app-blog>
    </div>
  </div>
</div>
