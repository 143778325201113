import { Injectable } from '@angular/core';
import { Bookmark } from '../../interfaces/bookmark';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';

@Injectable({
  providedIn: 'root',
})
export class FavouritesService {
  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private apollo: Apollo
  ) {}

  getFavourites(): QueryRef<any, EmptyObject> {
    return this.apollo.watchQuery<Bookmark[]>({
      query: gql`
        query userFavourites {
          myFavourites(sort: "rank:asc", where: { bookmark_null: false }) {
            bookmark {
              id
              title
              shortDescription: short_description
              longDescription: long_description
              url: URL
              createdAt: created_at
              iconTitle: icon_title
              requestMail: request_mail
              samId: sam_id
              icons {
                name
                code
                description
                colour
              }
              category {
                title
              }
              user: maintained_by {
                username
                email
              }
              thumbnail {
                url
                name
              }
              bookmarkType: bookmark_type {
                title
              }
            }
          }
        }
      `,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });
  }

  checkIfFavourite(bookmark: Bookmark): Promise<boolean> {
    return this.getFavourites()
      .result()
      .then(
        (result: any) =>
          result.data.myFavourites.filter(
            (f) => Number(f.bookmark.id) === Number(bookmark.id)
          ).length > 0
      )
      .catch(() => false);
  }

  addFavourite(newFavouriteId: number): any {
    return this.http
      .post(
        environment.graphqlUri,
        {
          query: `
        mutation updateFavourites{
          addFavourite(input: {data: {bookmark: ${newFavouriteId}}}){
            bookmark{
              title
            }
          }
        }
      `,
        },
        { withCredentials: true }
      )
      .toPromise()
      .then((res) => {})
      .catch((res) => {});
  }

  removeFavourite(toRemoveFavouriteId: number): any {
    return this.http
      .post(
        environment.graphqlUri,
        {
          query: `
        mutation updateFavourites{
          removeFavourite(input: {data: {bookmark: ${toRemoveFavouriteId}}}){
            bookmark{
              title
            }
          }
        }
      `,
        },
        { withCredentials: true }
      )
      .toPromise()
      .then(() => {})
      .catch(() => {});
  }

  reorderFavourite(
    bookmarkToMove: number,
    bookmarkLeft: number,
    bookmarkRight: number
  ): any {
    return this.http
      .post(
        environment.graphqlUri,
        {
          query: `
        mutation reorderFavourite{
          reorderFavourite(input: {bookmarkToMove: ${bookmarkToMove}, bookmarkLeft: ${bookmarkLeft}, bookmarkRight: ${bookmarkRight}}){
            bookmark{
              title
            }
          }
        }
      `,
        },
        { withCredentials: true }
      )
      .toPromise()
      .then(() => {})
      .catch(() => {});
  }
}
