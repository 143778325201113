import {ThemeOption, Logo} from 'src/app/shared/interfaces/themeOption';
import {Component, Input, OnInit} from '@angular/core';
import {SideBarService} from '../../services/side-bar/side-bar.service';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild} from '@angular/router';
import {AuthService} from 'src/app/core/services/auth/auth.service';
import {Observable} from 'rxjs';
import {ThemeOptionsService} from 'src/app/shared/services/theme-options/theme-options.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  @Input() theme: ThemeOption;
  collapsed$ = this.sideBarService.getCollapsedState();
  sideBarItems$ = this.sideBarService.getPagesToRender();
  useFeedback$ = Boolean(environment.cannyTokenEndpoint);

  constructor(
    private sideBarService: SideBarService,
    private router: Router,
    private authService: AuthService,
    private themeOptionsService: ThemeOptionsService
  ) {}

  ngOnInit(): void {}

  toggleCollapse(): void {
    this.sideBarService.toggleSidebar();
  }

  logout(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | void | UrlTree> | Promise<boolean | void | UrlTree> {
    return this.authService.logout();
  }

  getImageURL(logo: Logo): string{
    if (!logo) {
      return '../../../assets/aps-logo-blue.png';
    }
    return environment.production ? logo.url : environment.baseUrl + logo.url;
  }

}
