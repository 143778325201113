import { FeedbackComponent } from './core/components/feedback/feedback.component';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { SsoHandlerComponent } from './shared/components/sso-handler/sso-handler.component';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { NgModule } from '@angular/core';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'favourites',
    loadChildren: () =>
      import('./favourites/favourites.module').then((m) => m.FavouritesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tools',
    loadChildren: () =>
      import('./tools/tools.module').then((m) => m.ToolsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'stories',
    loadChildren: () =>
      import('./stories/stories.module').then((m) => m.StoriesModule),
    canActivate: [AuthGuard],
  },
  { path: 'connect/keycloak/redirect', component: SsoHandlerComponent },
  { path: 'feedback', component: FeedbackComponent, canActivate: [AuthGuard] },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
