<div
  id="mobile-menu"
  class="mobile-container"
  style="width: 100%"
  [style.top.px]="(hideStatus$ | async) ? -50 : 0"
>
  <div class="mobile-menu-items">
    <div id="hamburger-menu" class="hamburger" (click)="toggleCollapse()">
      <span class="material-icons-outlined">menu</span>
    </div>
    <div class="logo">
      <a href="/" style="width: 100%; height: 100%">
        <img
          style="height: 100%"
          src="../../../assets/aps-logo-blue.png"
          alt="APS logo"
        />
      </a>
    </div>
  </div>
  <div
    id="mobile-menu-expansion"
    class="mobile-menu-expansion"
    [class.hidden]="collapsed$ | async"
  >
    <ng-template #loading>
      <div class="d-flex justify-content-center">
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </ng-template>
    <ng-container *ngIf="sideBarItems$ | async as sideBarItems; else loading">
      <ul>
        <li
          class="list-item"
          [class.selected]="router.url === item.path"
          *ngFor="let item of sideBarItems; index as i"
        >
          <a
            class="d-flex align-items-center"
            (click)="toggleCollapse()"
            [routerLink]="item.path"
            tabindex="{{ i }}"
          >
            <div
              *ngIf="item.iconTitle; else iconSrc"
              class="material-icons-outlined feature-icon"
            >
              {{ item.iconTitle }}
            </div>
            <ng-template #iconSrc>
              <img
                class="feature-icon icon"
                src="../../../assets/svgs/{{ item.src }}.svg"
                [alt]="item.name"
              />
            </ng-template>
            <span
              class="feature-nav-label"
              [class.collapsed]="collapsed$ | async"
              >{{ item.name }}</span
            >
          </a>
        </li>
        <li *ngIf="useFeedback" class="list-item" [class.selected]="router.url === '/feedback'">
          <a class="d-flex align-items-center" [routerLink]="['/feedback']">
            <div class="material-icons-outlined feature-icon li">
              rate_review
            </div>
            <span class="feature-nav-label">Give Feedback</span>
          </a>
        </li>
        <li class="list-item">
          <a
            class="d-flex align-items-center cursor-pointer"
            (click)="logout()"
          >
            <div class="material-icons-outlined feature-icon">logout</div>
            <span class="feature-nav-label">Logout</span>
          </a>
        </li>
      </ul>
    </ng-container>
  </div>
</div>
