<ng-template #loading>
  <div class="d-flex justify-content-center">
    <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<div class="container pt-3">
  <app-breadcrumb [items]="breadcrumbs" color="theme"></app-breadcrumb>
  <div class="text-center">
    <h1 class="page-title">{{ newsTitle }}</h1>
    <h2 class="page-subtitle">Updates on projects, introducing new team members & sharing the knowledge</h2>
  </div>
  <div class="row d-flex justify-content-center">
    <div class="col-md-4 col-10">
      <app-search-input
        [(inputModel)]="searchInput"
        (input)="filterBlogs()"
      ></app-search-input>
    </div>
  </div>

  <ng-container *ngIf="filteredPromotedBlogs.length > 0 || filteredBlogs.length > 0; else noBlogs">
    <div class="d-flex">
      <app-promoted-news-list class="w-100" [useFavouritingHeart]="true" [blogs]="getBlogsFromThemeOptions(filteredPromotedBlogs, 3)"></app-promoted-news-list>
    </div>

    <div class="d-flex blogs-list">
      <app-blogs-list class="w-100 pb-5" [blogs]="filteredBlogs"></app-blogs-list>
    </div>
  </ng-container>

  <ng-template #noBlogs>
    <h2 class="pt-4">No {{ news }} added yet!</h2>
  </ng-template>
</div>
