<div class="row d-flex bookmark-card w-100 m-0" tabindex="0">
  <div class="row w-100 m-0">
    <div class="col">
      <!-- Avatar -->
      <app-avatar
        [thumbnail]="bookmark.thumbnail"
        [iconTitle]="bookmark.iconTitle"
      ></app-avatar>
    </div>

    <div class="col-7 d-flex align-items-between ml-2">
      <div class="row">
        <span class="d-flex w-100 bookmark-title">
          {{ bookmark.title }}
        </span>

        <!-- Category -->
        <span class="d-flex w-100 bookmark-category mb-0">
          {{ bookmark.category.title }}
        </span>
      </div>
    </div>

    <div class="col p-0">
      <div
        *ngIf="useFavouritingHeart"
        role="button"
        (click)="favouriteClicked($event)"
        (keydown.enter)="favouriteClicked($event)"
        class="d-flex justify-content-end align-items-center"
        tabindex="0"
      >
        <div
          class="icon-container d-flex justify-content-center align-items-center"
        >
          <span class="material-icons-filled">
            {{ isFavourite ? "favorite" : "favorite_bordered" }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex w-100 justify-content-between row align-items-end m-0">
    <!-- Extra info -->
    <div class="col">
      <div class="text">
        <span
          (click)="navigateToDetailsPage(bookmark)"
          (keydown.enter)="navigateToDetailsPage(bookmark)"
          tabindex="0"
          class="bookmark-extra-info d-flex align-items-center justify-content-center"
        >
          {{ bookmarkInfo | uppercase }}
        </span>
      </div>
    </div>
    <div
      class="icon-container d-flex justify-content-center align-items-center"
    >
      <span
        class="material-icons-outlined"
        (click)="redirect(bookmark.url)"
        (keydown.enter)="redirect(bookmark.url)"
        tabindex="0"
        aria-label="Open the tool"
        role="button"
      >
        open_in_new
      </span>
    </div>
  </div>
</div>
