import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {LocalStorageService} from 'src/app/shared/services/local-storage/local-storage.service';

@Component({
  selector: 'app-sso-handler',
  template: '',
  styles: ['']
})
export class SsoHandlerComponent implements OnInit {

  constructor(private localStorageService: LocalStorageService, private router: Router) { }

  ngOnInit(): void {
    this.redirectToLastRoute();
  }

  redirectToLastRoute(): void{
    const redirectPath = this.localStorageService.getLocalDataByKey('redirectPath') || '/';
    this.router.navigate([redirectPath]);
  }

}
