import { Blog } from '../../interfaces/blog';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Apollo, gql, QueryRef } from 'apollo-angular';
import { EmptyObject } from 'apollo-angular/types';

@Injectable({
  providedIn: 'root',
})
export class FavouriteStoriesService {
  constructor(
    private http: HttpClient,
    private apollo: Apollo
  ) {}

  myFavouriteStories(): QueryRef<any, EmptyObject> {
    return this.apollo.watchQuery<Blog[]>({
      query: gql`
        query favouriteStories{
          myFavouriteStories(sort: "rank:asc", where: {blog_null: false}){
            blog{
              id
              title
              description
              content
              user: maintained_by{
                username
                email
                name
              }
              thumbnail{
                  url
                  name
              }
              updatedAt: updated_at
              createdAt: created_at
              displayDate: display_date
            }
          }
        }
      `,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    });
  }

  checkIfFavouriteStory(blog: Blog): Promise<boolean> {
    return this.myFavouriteStories().result().then((result: any) => result.data.myFavouriteStories.filter(f => Number(f.blog.id) === Number(blog.id)).length > 0).catch(() => false);
  }

  addFavouriteStory(newFavouriteId: number): any {
    return this.http.post(environment.graphqlUri, {
          query: `
        mutation updateFavouriteStories{
          addFavouriteStory(input: {data: {blog: ${newFavouriteId}}}){
            blog {
              title
            }
          }
        }
      `,
        },
        { withCredentials: true }
      )
      .toPromise()
      .then((res) => {})
      .catch((res) => {});
  }

  removeFavouriteStory(toRemoveFavouriteId: number): any {
    return this.http
      .post(
        environment.graphqlUri,
        {
          query: `
        mutation updateFavouriteStories{
          removeFavouriteStory(input: {data: {blog: ${toRemoveFavouriteId}}}){
            blog {
              title
            }
          }
        }
      `,
        },
        { withCredentials: true }
      )
      .toPromise()
      .then(() => {})
      .catch(() => {});
  }

  reorderFavouriteStory(
    blogToMove: number,
    blogLeft: number,
    blogRight: number
  ): any {
    return this.http
      .post(
        environment.graphqlUri,
        {
          query: `
        mutation reorderFavouriteStory{
          reorderFavouriteStory(input: {blogToMove: ${blogToMove}, blogLeft: ${blogLeft}, blogRight: ${blogRight}}){
            blog {
              title
            }
          }
        }
      `,
        },
        { withCredentials: true }
      )
      .toPromise()
      .then(() => {})
      .catch(() => {});
  }
}
