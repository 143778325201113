import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hex2rgb',
})
export class Hex2rgbPipe implements PipeTransform {
  transform(hex: string): any {
    return hex
      .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
  }
}
