<div class="container vh-100 d-flex justify-content-center align-items-center">
  <div id="errorPage" class="row">
    <img class="page-not-found-image col-7" src="../../../assets/svgs/404-page-not-found.svg" alt="404 not found"/>

    <div id="textContainer" class="col-5 justify-content-center text-center">
      <h1 class="big-text">
        404
      </h1>

      <h1 class="display-3">
        Hmmm...how did it happen?
      </h1>

      <h1>
        We've been trying to find what you were looking for, but had no luck.
      </h1>

      <div>
        <h1>
          Meanwhile, let's find a better place for you to go.
        </h1>
      </div>

      <div class="row d-flex justify-content-center">
        <button class="btn btn-primary small" [routerLink]="''" tabindex="0">
          <span data-feather="home" class="col-auto"></span>
          <span class="col-auto">Go back home</span>
        </button>
      </div>

    </div>

  </div>
</div>
