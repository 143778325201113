<div>
  <div *ngIf="loading">
    <div class="d-flex justify-content-center">
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <div *ngIf="!loading && filteredFavourites" class="fill-page pb-6">
    <div class="container pt-3">
      <app-breadcrumb color="theme" [items]="breadcrumbs"></app-breadcrumb>
      <div [ngSwitch]="theme?.usLocale" class="row d-flex justify-content-center align-items-center">
        <h1 *ngSwitchCase="true" class="page-title w-100 text-center">
          My favorites
        </h1>
        <h1 *ngSwitchDefault class="page-title w-100 text-center">
          My favourites
        </h1>
      </div>

      <div class="row d-flex justify-content-center">
        <div class="col-md-4 col-10">
          <app-search-input
            [(inputModel)]="searchInput"
            (input)="filterBookmarks()"
          ></app-search-input>
        </div>
      </div>
      <div class="container p-0 mb-4">
        <app-drag-and-drop-tools
        *ngIf="favourites.length > 0; else noFavourites"
        (removeItemEmitter)="removeFavourite($event)"
        [bookmarks]="filteredFavourites"
        [rowCount]="getRowCount(favourites.length)"
        [useFavouritingHeart]="true"
      ></app-drag-and-drop-tools>

      <ng-template #noFavourites>
        <h1 [ngSwitch]="theme?.usLocale">
          <em *ngSwitchCase="true" class="text-muted pt-3">
            No favorite tools added yet, click on
            <a class="text-muted" tabindex="0" [routerLink]="['/tools']"><u>here</u></a> to
            add some!
          </em>

          <em *ngSwitchDefault class="text-muted pt-3">
            No favourite tools added yet, click on
            <a class="text-muted" tabindex="0" [routerLink]="['/tools']"><u>here</u></a> to
            add some!
          </em>
        </h1>
      </ng-template>
      </div>
      <div *ngIf="!theme?.hideStories" class="container p-0 mt-4">
        <app-drag-and-drop-stories
          *ngIf="favouriteStories.length > 0; else noFavouriteStories"
          (removeItemEmitter)="removeFavouriteStory($event)"
          [blogs]="filteredFavouriteStories"
          [rowCount]="getRowCount(favouriteStories.length)"
          [isFavourite]="true"
          [useFavouritingHeart]="true"
        ></app-drag-and-drop-stories>

      <ng-template #noFavouriteStories>
        <h1 [ngSwitch]="theme?.usLocale">
          <em *ngSwitchCase="true" class="text-muted pt-3">
            No favorite stories added yet, click on
            <a class="text-muted" tabindex="0" [routerLink]="['/stories']"><u>here</u></a> to
            add some!
          </em>

          <em *ngSwitchDefault class="text-muted pt-3">
            No favourite stories added yet, click on
            <a class="text-muted" tabindex="0" [routerLink]="['/stories']"><u>here</u></a> to
            add some!
          </em>
        </h1>
      </ng-template>
      </div>
    </div>
  </div>
</div>
