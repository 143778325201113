import { Component, Input } from '@angular/core';
import { BlogsService } from '../../../../shared/services/blogs/blogs.service';
import { Blog } from '../../../../shared/interfaces/blog';

@Component({
  selector: 'app-blogs-list',
  templateUrl: './blogs-list.component.html',
  styleUrls: ['./blogs-list.component.scss']
})
export class BlogsListComponent {
  @Input() blogs: Blog[] = [];

  constructor(public blogService: BlogsService) { }
}
