import { Category } from './category';
import { User } from './user';
import { Thumbnail } from './thumbnail';
import { BookmarkType } from './bookmarkType';
import { Icon } from './icon';
import { Manual } from './manual';

export interface Bookmark {
  id: number;
  title: string;
  shortDescription: string;
  longDescription: string;
  url: string;
  createdAt: string;
  iconTitle: string;
  samId: string;
  demoMode?: boolean;
  caseStudies?: string;
  caseStudiesTitle?: string;
  requestMail: string;
  icons: Icon[];
  user: User;
  category: Category;
  thumbnail: Thumbnail;
  manuals: Manual[];
  bookmarkType: BookmarkType;
}
