import { Hex2rgbPipe } from './shared/pipes';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SideBarService } from './core/services/side-bar/side-bar.service';
import { appConstants } from './constants';
import { HideMobileMenuService } from './core/services/hide-mobile-menu/hide-mobile-menu.service';
import { ThemeOptionsService } from './shared/services/theme-options/theme-options.service';
import { ThemeOption } from 'src/app/shared/interfaces/themeOption';
import { FooterService } from './core/services/footers/footers.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  footerContent$;
  collapsed$ = this.sideBarService.getCollapsedState();
  pageWidth = window.innerWidth;
  useMobileMenu =
    this.pageWidth <= appConstants.minimumRequiredWidthInPixelsForNonMobileView;
  scrollTop = 0;
  themeOption$: ThemeOption;

  // Angular cannot render outside its own containers. However, the app is the main container.
  // The app component is responsible for facilitating the rendering.
  // Since the app component manages rendering, it should also be in charge of changing the favicon.
  favicon: HTMLLinkElement = document.querySelector('#favicon');

  constructor(
    private router: Router,
    private sideBarService: SideBarService,
    public hideMobileMenuService: HideMobileMenuService,
    private themeOptionsService: ThemeOptionsService,
    private footerService: FooterService,
    private hex2rgbPipe: Hex2rgbPipe,
    private ngZone: NgZone,
    private titleService: Title
  ) {
    // Zone.js causes normal DOM events to cause angular reaction updates
    // To ensure scrolling doesn't rerender things like rendered HTML payload containing interactive elements, such a video,
    // we had to add these events in a way it doesn't angular updates
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', () => {
        if (this.pageWidth <= 768) {
          this.hideMobileMenuService.setHideStatus(
            this.scrollTop < window.pageYOffset
          );
          this.sideBarService.setCollapsedState(true);
          this.scrollTop = window.pageYOffset;
        }
      });
      window.addEventListener('resize', () => {
        this.pageWidth = window.innerWidth;
        this.useMobileMenu =
          this.pageWidth <=
          appConstants.minimumRequiredWidthInPixelsForNonMobileView;
        if (
          this.pageWidth <= appConstants.maxWidthAllowCollapse &&
          this.pageWidth > 768
        ) {
          this.sideBarService.setCollapsedState(true);
        }
      });
    });
  }

  async ngOnInit(): Promise<any> {
    this.titleService.setTitle(environment.siteTitle);
    this.themeOption$ = await this.themeOptionsService.getThemeOption();
    const theme = this.themeOption$?.theme?.myTheme;
    if (theme) {
      this.setBodyStylingUsingTheme(theme);
      const faviconHref = this.getFaviconFromTheme(theme);
      this.setHTMLFavicon(faviconHref);
    }
    this.footerContent$ = await this.footerService
      .getFooterColumns('footer')
      .toPromise();
  }

  getFooterContent() {
    return this.footerContent$?.contentColumns || undefined;
  }

  setBodyStylingUsingTheme(theme: ThemeOption) {
    if (theme) {
      const bodyStyles = document.body.style;
      const [primaryRed, primaryGreen, primaryBlue] =
        this.hex2rgbPipe.transform(theme.primaryColor);
      const [accentRed, accentGreen, accentBlue] = this.hex2rgbPipe.transform(
        theme.accentColor
      );

      bodyStyles.setProperty('--theme-primary', theme.primaryColor);
      bodyStyles.setProperty('--theme-accent', theme.accentColor);
      bodyStyles.setProperty('--theme-secondary', theme.secondaryColor);
      bodyStyles.setProperty('--theme-green', theme.secondaryColor);
      bodyStyles.setProperty('--theme-background', theme.backgroundColor);
      bodyStyles.setProperty('--theme-header-bg', theme.headerBgColor);
      bodyStyles.setProperty('--theme-header-title', theme.headerTitleColor);
      bodyStyles.setProperty(
        '--theme-primary-rgba',
        `${primaryRed}, ${primaryGreen}, ${primaryBlue}`
      );
      bodyStyles.setProperty(
        '--theme-accent-rgba',
        `${accentRed}, ${accentGreen}, ${accentBlue}`
      );
    }
  }

  getFaviconFromTheme(theme: ThemeOption): string {
    const href = theme?.favicon?.url
      ? environment.production
        ? theme.favicon.url
        : environment.baseUrl + theme.favicon.url
      : 'https://www.theapsgroup.com/wp-content/uploads/2021/11/logo.svg';
    return href;
  }

  setHTMLFavicon(href: string) {
    this.favicon.href = href;
  }
}
