import {Injectable} from '@angular/core';
import {LocalStorageService} from '../../../shared/services/local-storage/local-storage.service';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {User} from '../../../shared/interfaces/user';
import {Apollo, gql} from 'apollo-angular';
import {ApolloQueryResult} from '@apollo/client/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private name: string;
  private email: string;

  constructor(private localStorageService: LocalStorageService, private router: Router, private http: HttpClient, private apollo: Apollo) {}

  redirectToLoginPage(routerPath?: string): void {
    this.localStorageService.updateLocalStorage('redirectPath', routerPath || this.router.url);
    window.location.assign(environment.keyCloakLogin);
  }

  logout(): Promise<any> {
    return this.http
      .post(environment.logout, null, {withCredentials: true})
      .toPromise()
      .then(() => {
        const ssoLogoutUrl = `https://${
          environment.ssoLink
        }.apsmos.com/auth/realms/MOS/protocol/openid-connect/logout?post_logout_redirect_uri=${window.location.toString()}`;
        window.location.assign(ssoLogoutUrl);
      });
  }

  async checkIfLoggedIn(): Promise<User> {
    return this.apollo.client
      .query<any>({
        query: gql`
          query me {
            me {
              user {
                username
                name
                email
              }
            }
          }
        `,
        fetchPolicy: 'network-only',
      })
      .then((response: ApolloQueryResult<any>) => {
        const user: User = response.data.me.user;
        this.name = user.name;
        this.email = user.email;
        return user;
      })
      .catch((err) => {
        console.error(err);
        this.redirectToLoginPage();
        return null;
      });
  }

  getUsername(): string {
    return this.name;
  }

  getEmail(): string {
    return this.email;
  }
}
