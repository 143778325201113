import {Component, Input, OnInit} from '@angular/core';
import {Thumbnail} from '../../interfaces/thumbnail';
import {ContentService} from '../../services/content/content.service';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() thumbnail: Thumbnail;
  @Input() iconTitle: string;

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
  }

  getImageURL(): string{
    return this.contentService.getImageURL(this.thumbnail);
  }
}
