<div>
  <div *ngIf="loading">
    <div class="d-flex justify-content-center">
      <div
        class="spinner-border"
        style="width: 3rem; height: 3rem"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  <div *ngIf="error">Error: {{ error }}</div>
  <div *ngIf="!loading && filteredBookmarks" class="fill-page">
    <div class="container pt-3">
      <app-breadcrumb color="theme" [items]="breadcrumbs"></app-breadcrumb>
      <div class="row display-3 mt-3">
        <h1 class="page-title w-100 text-center">
          Tools to help you stay productive
        </h1>
      </div>

      <div class="row d-flex justify-content-center filtration-area">
        <div class="col-md-4 col-8">
          <app-search-input
            [(inputModel)]="searchInput"
            (input)="filterBookmarks()"
          ></app-search-input>
        </div>

        <div class="dropdown col-auto d-flex flex-wrap align-items-stretch">
          <button
            class="btn filter-btn d-flex align-items-center"
            data-toggle="dropdown"
          >
            <span
              class="material-icons-outlined filter-icon"
              [attr.aria-hidden]="true"
              >filter_alt</span
            >
            FILTER
          </button>

          <div class="dropdown-menu dropdown-menu-right">
            <button
              class="dropdown-item"
              [class.active-category]="categoryFilter === ''"
              type="button"
              [routerLink]="['/tools']"
            >
              All
            </button>
            <button
              class="dropdown-item"
              [class.active-category]="categoryFilter === category.title"
              type="button"
              *ngFor="let category of allCategories"
              [routerLink]="['/tools']"
              [queryParams]="{ category: category.title }"
            >
              {{ category.title }}
            </button>
          </div>
        </div>
      </div>

      <ul class="nav nav-tabs nav-overflow row">
        <li class="nav-item" *ngFor="let bookmarkType of allBookmarkTypes">
          <h2
            class="nav-link cursor-pointer nav-tab-title"
            [ngClass]="bookmarkType === selectedBookmarkType ? 'active' : ''"
            (click)="setBookmarkType(bookmarkType)"
            [attr.aria-label]="bookmarkType.title"
            tabindex="0"
          >
            {{ bookmarkType.title }} ({{ getBookmarkCount(bookmarkType) }})
          </h2>
        </li>
      </ul>

      <div
        class="tab-content"
        id="nav-tabContent"
        *ngIf="filteredBookmarks.length > 0; else noBookmarks"
      >
        <div
          *ngFor="let bookmarkCategory of filteredBookmarks"
          class="bookmark-list"
        >
          <div class="row">
            <h2 class="section-title col-12">{{ bookmarkCategory.title }}</h2>
          </div>
          <div
            *ngFor="
              let rowNr of applicationService.calculateRowCount(
                columnSize,
                bookmarkCategory.bookmarks.length
              ) | range
            "
            class="row"
          >
            <div
              *ngFor="
                let bookmark of applicationService.getApplicationsFromRange(
                  bookmarkCategory.bookmarks,
                  columnSize * rowNr,
                  columnSize
                )
              "
              class="col-xl-4 col-lg-6 col-12 my-3 bookmark"
            >
              <app-application-card
                [useFavouritingHeart]="true"
                [bookmark]="bookmark"
              ></app-application-card>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noBookmarks class="tab-content">
        <h4 class="no-bookmarks">
          <em class="text-muted pt-3"> No bookmarks found for your search </em>
        </h4>
      </ng-template>
    </div>
  </div>
</div>
