<div class="row" [ngClass]="padding">
  <div class="col">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item"
          [ngClass]="'breadcrumb-' + color"
          *ngFor="let item of items"
        >
          <a
            [routerLink]="item.slug"
            [ngClass]="'text-' + color"
            tabindex="0"
            >{{ item.label }}</a
          >
        </li>
      </ol>
    </nav>
  </div>
</div>
