import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Bookmark} from '../../../shared/interfaces/bookmark';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {User} from '../../../shared/interfaces/user';
import {AuthService} from '../../../core/services/auth/auth.service';
import {ToastService} from '../../../core/services/toast/toast.service';

@Component({
  selector: 'app-request-access-modal',
  templateUrl: './request-access-modal.component.html',
  styleUrls: ['./request-access-modal.component.scss']
})
export class RequestAccessModalComponent implements OnInit {
  @Input() bookmark: Bookmark;
  @ViewChild('unauthorized') unauthorizedTemplateRef: TemplateRef<any>;
  closeModalButton: HTMLElement;
  textInput: string;
  namesArray: string[] = [];
  user: User;

  constructor(private http: HttpClient, private toastService: ToastService,
              private authService: AuthService) { }

  async ngOnInit(): Promise<void> {
    this.closeModalButton = document.getElementById('close-modal-button') as HTMLElement;

    if (!this.user){
      this.user = await this.authService.checkIfLoggedIn();
      this.namesArray.push(this.user.name);
    }
  }

  requestAccess(): void{
    if (this.namesArray.length < 1){
      this.toastService.show('Please fill in names to request to!', { classname: 'bg-danger text-light', delay: 5000 });
      return;
    }
    const body = {
      appId: this.bookmark.id,
      usersToRequest: this.namesArray
    };
    this.http.post(`${environment.baseUrl}/request`, body,
      {
        withCredentials: true
      }).toPromise().then((response: any) => {
      this.toastService.show(response.message, { classname: 'bg-success text-light', delay: 5000 });
    }).catch((err) => {
      if (err.status === 401){
        this.toastService.show(this.unauthorizedTemplateRef, { classname: 'bg-danger text-light', delay: 5000 });
      } else {
        this.toastService.show(err.error, { classname: 'bg-danger text-light', delay: 5000 });
      }
    });

    this.closeModalButton.click();
  }

  addName(nameToAdd): void{
    this.namesArray.push(nameToAdd);
    this.textInput = '';
  }

  removeName(indexToRemove): void{
    this.namesArray.splice(indexToRemove, 1);
  }

  clearAllNames(): void{
    this.namesArray = [];
  }

}
