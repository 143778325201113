import { Component, EventEmitter, HostListener, Input, Output, OnInit } from '@angular/core';
import {Blog} from '../../interfaces/blog';
import {ContentService} from '../../services/content/content.service';
import DateTimeFormatOptions = Intl.DateTimeFormatOptions;
import {Router} from '@angular/router';
import { ThemeOption } from '../../interfaces/themeOption';
import { FavouriteStoriesService } from 'src/app/shared/services/favourite-stories/favourite-stories.service';
@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  @Input() theme: ThemeOption;
  @Input() blog: Blog;
  @Input() promoted = false;
  @Input() useFavouritingHeart: boolean;
  @Output() removeFavouriteEmitter = new EventEmitter<number>();
  isFavourite: boolean;
  pageWidth = window.innerWidth;
  maxCharacterSizeBlogCardText = this.calculateAllowedCharactersBlogCardText();
  maxCharactersBlogCardTitle = this.calculateAllowedCharactersBlogCardTitle();

  constructor(public contentService: ContentService, private router: Router, public favouriteStoriesService: FavouriteStoriesService) { }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.pageWidth = window.innerWidth;

    this.maxCharacterSizeBlogCardText = this.calculateAllowedCharactersBlogCardText();
    this.maxCharactersBlogCardTitle = this.calculateAllowedCharactersBlogCardTitle();
  }

  ngOnInit(): void {
    this.checkIfFavouriteStory();
  }

  calculateAllowedCharactersBlogCardText(): number {
    return (this.pageWidth < 992 && this.pageWidth >= 768) ? (this.pageWidth < 800 ? 175 : 250) : 425;
  }

  calculateAllowedCharactersBlogCardTitle(): number {
    return this.pageWidth <= 380 ? 50 : 85;
  }

  getImageUrl(): string{
    return this.contentService.getImageURL(this.blog.thumbnail);
  }

  redirectToBlog(blog: Blog): void{
    const blogSlug = blog.title.toLowerCase().replace(/ /g, '-') + `-${blog.id}`;
    this.router.navigate(['/stories', blogSlug]);
  }

  favouriteClicked(event: Event): void{
    event.stopPropagation();
    if (this.isFavourite){
      this.isFavourite = false;
      this.favouriteStoriesService.removeFavouriteStory(this.blog.id);
      this.removeFavouriteEmitter.emit(this.blog.id);
    } else {
      this.isFavourite = true;
      this.favouriteStoriesService.addFavouriteStory(this.blog.id);
    }
  }
  checkIfFavouriteStory(): void{
    this.favouriteStoriesService.checkIfFavouriteStory(this.blog).then(result => {
      this.isFavourite = result;
    });
  }
}
